import React, { useRef, useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel, Alert } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomInput from '../forms/CustomInput'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import Assets from '../../constants/images'
import Loader from '../loader/Loader'
import { DownloadUserSampleCsv } from '../../services/users/users'
import { error } from '../../constants/msg'
import { saveAs } from "file-saver"

const UploadCsvPopup = ({ uploadCsvPopup, setUploadCsvPopup, bulkCreateMethod, isUploadDisabled, customError, setCustomError }) => {
    const [file, setFile] = useState(null)
    const { register, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const removeFile = () => {
        setFile(null)
        reset({
            file: null
        })
    }

    const downloadUserSampleCsv = async () => {
        try {
            const res = await DownloadUserSampleCsv()
            saveAs(res.data.data.link)
        }
        catch (e) {
            error(e.response.data.message)
        }
    }

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("file", file);
        await bulkCreateMethod(formData)
        removeFile()
    }

    useEffect(() => {
        removeFile()
        setCustomError("")
    }, [uploadCsvPopup])

    return (
        <Modal
            className='theme-popup upload-csv-popup'
            show={uploadCsvPopup}
            onHide={() => setUploadCsvPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper p-3">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Upload CSV</h1>
                                        <span className='times-icon' onClick={() => setUploadCsvPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='mt-4'>
                            <Row>
                                <Col xs={12} className={`${customError ? 'd-block' : 'd-none'}`}>
                                    <Alert variant="danger" dismissible onClose={() => setCustomError("")}>
                                        {customError && customError.split(",").join("\n")}
                                        <div id="div"></div>
                                    </Alert>
                                </Col>

                                <Col xs={12}>
                                    <label htmlFor="file" className='w-100'>
                                        <div className='upload-box'>
                                            <input
                                                className='d-none'
                                                type="file"
                                                id="file"
                                                {...register("file",
                                                    {
                                                        onChange: (e) => {
                                                            setFile(e.target.files[0])
                                                            setCustomError("")
                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.FILE_REQUIRED
                                                        },
                                                    })
                                                }
                                            />
                                            <div className="img-wrapper">
                                                <img src={Assets.CsvImage} alt="" />
                                            </div>
                                            <p className='mt-3'><b>{file ? file?.name : "Select a CSV file to upload"}</b></p>
                                        </div>
                                    </label>
                                    {errors.file && <small className='text-start d-block text-danger mt-1'>{errors.file.message}</small>}
                                </Col>

                                <Col xs={12}>
                                    <span className='mt-3 download-csv' onClick={downloadUserSampleCsv}>Download Sample CSV</span>
                                </Col>
                            </Row>
                        </div>

                        <Row className="justify-content-end mt-4">
                            <Col xs={6} sm={4}>
                                <Button className={`btn-outline w-100 ${file ? "d-block" : "d-none"}`} onClick={removeFile}>Remove File</Button>
                            </Col>

                            <Col xs={6} sm={4}>
                                <Button type="submit" disabled={isUploadDisabled} className='btn-solid w-100'>
                                    {isUploadDisabled ? <Loader /> : "Upload"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UploadCsvPopup