import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Assets from '../constants/images'

const SplashScreenPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate("/signin")
        }, [2500])
    }, [])

    return (
        <div className='splash-screen'>
            <div className='bg-image'></div>
            <div className='logo-wrapper'>
                <img src={Assets.Logo} alt="" />
            </div>
        </div>
    )
}

export default SplashScreenPage