import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import { ToastContainer } from 'react-toastify'
import CreateShiftSchedulePopup from '../components/popup/CreateShiftSchedulePopup'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import { BulkDeleteSchedule, DeleteSchedule, GetSchedules } from '../services/schedules/schedules'
import { error, success } from '../constants/msg'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'

const ShiftSchedulePage = () => {
    const [createShiftSchedulePopup, setCreateShiftSchedulePopup] = useState(false)
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([
        {
            name: 'Duration',
            selector: rowData => rowData.duration,
            sortable: true,
        },
        {
            name: 'Schedule Period ',
            selector: rowData => rowData.schedule_period,
            sortable: true,
        },
        {
            name: 'Site Name',
            selector: rowData => rowData.site_name,
            sortable: true,
        },
    ])

    const getSchedules = async () => {
        const rows = []
        setIsLoading(true)
        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
            }
            const res = await GetSchedules(params)
            const data = res.data
            data?.data?.forEach((item) => {
                rows.push({
                    id: item?.id.toString() || "-",
                    duration: item?.durations_display || "-",
                    schedule_period: item?.start_date + " - " + item?.end_date || "-",
                    site_name: item.site_name || "-",
                })
            })
            setRows(rows)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const deleteSchedule = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteSchedule(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteSchedule(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getSchedules()

        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        getSchedules()
    }, [page.currentPage])

    return (
        <>
            <CreateShiftSchedulePopup createShiftSchedulePopup={createShiftSchedulePopup} setCreateShiftSchedulePopup={setCreateShiftSchedulePopup} rows={rows} setRows={setRows} />
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={deleteSchedule} />
            <ToastContainer />

            <div className='pages shift-schedule-page'>
                <Container>
                    <Row className="justify-content-between">
                        <Col xs={12} sm={4} lg={4} xl={4} className="pt-2">
                            <h1>Shift Schedule</h1>
                        </Col>

                        <Col xs={12} sm={6} md={4} lg={3} xl={3}>
                            <Button className='mt-4 mt-sm-0 w-100 btn-solid' onClick={() => setCreateShiftSchedulePopup(true)}>+ Create Shift Schedule</Button>
                        </Col>

                        <Col xs={12} className="mt-4">
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={true} toggledClearRows={toggledClearRows} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ShiftSchedulePage