import React, { useContext, useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import { ToastContainer } from 'react-toastify'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import { BsCloudArrowDownFill, BsCloudArrowUpFill } from 'react-icons/bs'
import AddUserPopup from '../components/popup/AddUserPopup'
import AddSitePopup from '../components/popup/AddSitePopup'
import { useForm } from 'react-hook-form'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import { error, success } from '../constants/msg'
import { BulkDeleteSite, DeleteSite, GetSites } from '../services/sites/sites'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import CustomSearchInput from '../components/forms/CustomSearchInput'
import EditSitePopup from '../components/popup/EditSitePopup'
import ClearFormPopup from '../components/popup/ClearFormPopup'
import { TriggerAllSites } from '../App'

const ManageSitesPage = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [addSitePopup, setAddSitePopup] = useState(false)
    const [editSitePopup, setEditSitePopup] = useState(false)
    const [search, setSearch] = useState("")
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [rows, setRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [triggerAllSites, setTriggerAllSites] = useContext(TriggerAllSites)

    const [columns, setColumns] = useState([
        {
            name: 'Site ID',
            selector: rowData => rowData.id,
            sortable: true,
        },
        {
            name: 'Site Location',
            selector: rowData => rowData.site_location,
            sortable: true,
        },
        {
            name: 'Site Admin',
            selector: rowData => rowData.site_admin,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (rowData, row) => <ThemeDataTableAction link="/manage-sites/site" row={row} rowData={rowData} setEditPopup={setEditSitePopup} setSelectedRow={setSelectedRow} viewAction={true} />
        },
    ])

    const getSites = async () => {
        const rows = []
        setIsLoading(true)

        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
                ...(search && { search }),
            }
            const res = await GetSites(params)
            const data = res.data
            data?.data?.forEach((item) => {
                rows.push({
                    id: item?.id.toString() || "-",
                    site_location: item?.location || "-",
                    site_admin: item?.site_admin?.full_name || "-",
                })
            })
            setRows(rows)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const deleteSite = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteSite(selectedRowsForDelete[0]?.id)
                setTriggerAllSites(!triggerAllSites)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteSite(idsArray)
                setTriggerAllSites(!triggerAllSites)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getSites()
        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        getSites()
    }, [page.currentPage, search])

    return (
        <>
            <ToastContainer />
            <AddSitePopup addSitePopup={addSitePopup} setAddSitePopup={setAddSitePopup} rows={rows} setRows={setRows} />
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={deleteSite} />
            <EditSitePopup editSitePopup={editSitePopup} setEditSitePopup={setEditSitePopup} selectedRow={selectedRow} getSites={getSites} />

            <div className='pages manage-sites-page'>
                <Container>
                    <Row>
                        <Col xs={12} className="pt-2">
                            <h1>Site Management</h1>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} sm={6} lg={4} className="mb-4">
                            <CustomSearchInput
                                search={search}
                                onChange={(e) => setSearch(e.target.value)}
                                label="Search"
                                placeholder="Search"
                            />
                        </Col>

                        <Col xs={12} sm={6} lg={8}>
                            <Row className="justify-content-end">
                                <Col xs={12} sm={8} md={6} lg={4} xl={3} className="mb-4">
                                    <Button className='w-100 btn-solid' onClick={() => setAddSitePopup(true)}>+ Add New Site</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className="mt-4">
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={true} toggledClearRows={toggledClearRows} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ManageSitesPage