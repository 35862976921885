import React, { useState, useEffect } from 'react'
import { GetSites } from '../../services/sites/sites'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import { event_type_options, schedule_options } from '../../utils/constants'

const CustomScheduleSelect = (props) => {
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        if (!props.getValues(`${FIELD.event}`) || props.getValues(`${FIELD.event}`)?.value === 1) {
            /*
                If no event is selected or event = 1 than clear schedule select 
                Disabled the schedule if there is no event
            */
            setIsDisabled(true)
            props.setValue(`${FIELD.schedule}`, null)
        }
        else {
            setIsDisabled(false)
        }
    }, [props.state])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={isDisabled}
            isLoading={false}
            isClearable={props.isClearable}
            options={schedule_options}
        />
    )
}

export default CustomScheduleSelect