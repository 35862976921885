import React, { useState, useEffect } from 'react'
import { GetSites } from '../../services/sites/sites'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import { event_type_options } from '../../utils/constants'

const CustomEventTypeSelect = (props) => {
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        if (!props.getValues(`${FIELD.user}`)) {
            /*
                If no user is selected than clear event select 
                Disabled the event if there is no user
            */
            setIsDisabled(true)
            props.setValue(`${FIELD.event}`, null)
        }
        else{
            setIsDisabled(false)
        }
    }, [props.state])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={isDisabled}
            isLoading={false}
            isClearable={props.isClearable}
            options={event_type_options}
        />
    )
}

export default CustomEventTypeSelect