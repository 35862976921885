import React, { useState, useContext, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import { FIELD, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { teams_options } from '../../utils/constants';
import { CleanAndFormatData, GetOnlyMondays, GetOnlyThursdaysAndFridays } from '../../utils/helper';
import CustomDatePicker from '../forms/CustomDatePicker';
import CustomDepartmentSelect from '../forms/CustomDepartmentSelect';
import CustomDoubleTimeDaySelect from '../forms/CustomDoubleTimeDaySelect';
import CustomInput from '../forms/CustomInput';
import CustomOtCriteriaSelect from '../forms/CustomOtCriteriaSelect';
import CustomShiftSelect from '../forms/CustomShiftSelect';
import CustomSiteSelect from '../forms/CustomSiteSelect';
import CustomSliderToleranceSelect from '../forms/CustomSliderToleranceSelect';
import Loader from '../loader/Loader';
import ClearFormPopup from '../popup/ClearFormPopup';
import moment from 'moment';
import { error, success } from '../../constants/msg';
import { AddDupontSchedule } from '../../services/work-schedules/work-schedules';
import { ToastContainer } from 'react-toastify';
import { TriggerAllSites } from '../../App';

const SetWorkSchedule = (props) => {
    const { register, handleSubmit, reset, watch, setError, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const mondayDaysIndexes = [0, 1, 2, 3]
    const shift_a = watch(FIELD.team_a)?.value
    const shift_b = watch(FIELD.team_b)?.value
    const shift_c = watch(FIELD.team_c)?.value
    const shift_d = watch(FIELD.team_d)?.value
    const [selectedOptions, setSelectedOptions] = useState([])
    const [shiftOptions, setShiftOptions] = useState(teams_options)
    const site = watch(`${FIELD.site}`)
    const department = watch(`${FIELD.department}`)
    const [departmentName, setDepartmentName] = useState("")
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [excludeDates, setExcludeDates] = useState(GetOnlyMondays(new Date()))
    const [triggerAllSites, setTriggerAllSites] = useContext(TriggerAllSites)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null,
    })

    const onMonthChange = (value) => {
        if (mondayDaysIndexes.includes(props.index)) {
            const excludesDates = GetOnlyMondays(value)
            setExcludeDates(excludesDates)
        }
        else {
            const excludesDates = GetOnlyThursdaysAndFridays(value)
            setExcludeDates(excludesDates)
        }
    }

    const clearForm = () => {
        reset({
            [FIELD.site]: null,
            [FIELD.department]: null,
            [FIELD.department_name]: "",
            [FIELD.team_a]: null,
            [FIELD.team_b]: null,
            [FIELD.team_c]: null,
            [FIELD.team_d]: null,
            [FIELD.date_a]: null,
            [FIELD.date_b]: null,
            [FIELD.date_c]: null,
            [FIELD.date_d]: null,
            [FIELD.ot_criteria]: null,
            [FIELD.slider_tolerance]: null,
            [FIELD.double_time_day]: null,

        })
        setSelectedData({
            role: null, site: null, department: null, team: null, user: null, departmentName: ""
        })
        setClearFormPopup(false)
    }

    const handleChange = (value) => {
        setDepartmentName(value)
        if (value.length > 0) {
            setValue(`${FIELD.department}`, null)
        }
    }

    const handleShiftOptions = (label, value) => {
        if (value) {
            const temp = [...selectedOptions]
            let index
            let bool = false

            for (let i = 0; i < temp.length; i++) {
                if (temp[i].label === label) {
                    bool = true
                    return index
                }
            }
            if (bool) {
                temp[index] = {
                    label: label,
                    value: value
                }
                setSelectedOptions(temp)
            }
            else {
                temp.push({
                    label: label,
                    value: value
                })
                setSelectedOptions(temp)
            }
        }
        else {
            const temp = [...selectedOptions]
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].label === label) {
                    temp.splice(i, 1)
                    setSelectedOptions(temp)
                    return
                }
            }
        }
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        try {
            const formData = CleanAndFormatData(data)
            formData["name"] = departmentName ? departmentName : department?.label
            if (props.index !== 0) {
                delete formData[FIELD.date_a]
                delete formData[FIELD.date_b]
                delete formData[FIELD.date_c]
                delete formData[FIELD.date_d]
            }
            const res = await props.method(formData)
            clearForm()
            setSelectedOptions([])
            setTriggerAllSites(!triggerAllSites)
            success(res.data.message)
            setIsDisabled(false)

        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        // if department is selected than unset value of department name
        if (department) {
            setDepartmentName("")
            setValue(`${FIELD.department_name}`, "")
        }
        // if no is selected than unset value of department name
        if (!site) {
            setDepartmentName("")
            setValue(`${FIELD.department_name}`, "")
        }
    }, [department, site])

    useEffect(() => {
        handleShiftOptions("shift_a", shift_a)
    }, [shift_a, site, department])

    useEffect(() => {
        handleShiftOptions("shift_b", shift_b)
    }, [shift_b, site, department])

    useEffect(() => {
        handleShiftOptions("shift_c", shift_c)
    }, [shift_c, site, department])

    useEffect(() => {
        handleShiftOptions("shift_d", shift_d)
    }, [shift_d, site, department])

    useEffect(() => {
        if (props.index) {
            if (mondayDaysIndexes.includes(props.index)) {
                setExcludeDates(GetOnlyMondays(new Date()))
            }
            else {
                setExcludeDates(GetOnlyThursdaysAndFridays(new Date()))
            }
        }
    }, [props])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />
            <ToastContainer />

            <div className='set-work-schedule'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        {/* SITE SELECT */}
                        <Col xs={12} className={`mb-4`}>
                            <Controller
                                control={control}
                                name={FIELD.site}
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomSiteSelect
                                        {...field}
                                        name={FIELD.site}
                                        placeholder={"Select Site *"}
                                        styles=""
                                        isClearable={true}
                                        isPreFilled={false}
                                        callApi={true}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                        </Col>

                        {/* DEPARTMENT SELECT */}
                        <Col xs={12} sm={5} className={`mb-4`}>
                            <Controller
                                control={control}
                                name={FIELD.department}
                                rules={{
                                    required: {
                                        value: (departmentName.length > 0) ? false : true,
                                        message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomDepartmentSelect
                                        {...field}
                                        name={FIELD.department}
                                        placeholder={"Select Department *"}
                                        styles=""
                                        isClearable={true}
                                        isPreFilled={false}
                                        callApi={true}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        selectedOptions={selectedOptions}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.department] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department].message}</small>}
                        </Col>

                        <Col xs={12} sm={2} className={`mt-4 mb-4 text-center`}>
                            <h2>OR</h2>
                        </Col>

                        {/* DEPARTMENT NAME */}
                        <Col xs={12} sm={5} className={`mb-4`}>
                            <Controller
                                control={control}
                                name={FIELD.department_name}
                                rules={{
                                    required: {
                                        value: false,
                                        message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        name={FIELD.department_name}
                                        value={departmentName}
                                        onChange={(e) => {
                                            handleChange(e.target.value)
                                        }}
                                        label="New Department Name *"
                                        placeholder={"New Department Name *"}
                                        isDisabled={(!site || (site && department)) ? true : false}
                                        controlId={FIELD.department_name}
                                        type="text"
                                    />
                                )}
                            />
                            {errors[FIELD.department_name] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department_name].message}</small>}
                        </Col>

                        {/* SHIFT A SELECT */}
                        <Col xs={12} sm={6} className="mb-4">
                            <Controller
                                control={control}
                                name={FIELD.team_a}
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.SHIFT_A_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomShiftSelect
                                        {...field}
                                        name={FIELD.team_a}
                                        label="Shift A *"
                                        placeholder={"Shift A *"}
                                        isDisabled={false}
                                        isClearable={true}
                                        isPreFilled={true}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                        options={shiftOptions.filter((data) => !selectedOptions.find(({ value }) => data.value === value))}
                                    />
                                )}
                            />
                            {errors[FIELD.team_a] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team_a].message}</small>}
                        </Col>

                        {/* SHIFT A DATE PICKER */}
                        <Col xs={12} sm={6} className={`mb-4 ${!props?.showStartDate ? "d-block" : "d-none"}`}>
                            <Controller
                                control={control}
                                name={FIELD.date_a}
                                rules={{
                                    required: {
                                        value: !props?.showStartDate ? true : false,
                                        message: VALIDATIONS_TEXT.SHIFT_A_DATE_A_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomDatePicker
                                        {...field}
                                        name={FIELD.date_a}
                                        placeholder={"A Shift Monday Days *"}
                                        isClearable={true}
                                        isDependent={false}
                                        isDisabled={site ? false : true}
                                        excludeDates={excludeDates}
                                        onMonthChange={onMonthChange}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.date_a] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.date_a].message}</small>}
                        </Col>

                        {/* SHIFT B SELECT */}
                        <Col xs={12} sm={6} className="mb-4">
                            <Controller
                                control={control}
                                name={FIELD.team_b}
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.SHIFT_B_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomShiftSelect
                                        {...field}
                                        name={FIELD.team_b}
                                        label="Shift B *"
                                        placeholder={"Shift B *"}
                                        isDisabled={false}
                                        isClearable={true}
                                        isPreFilled={true}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                        options={shiftOptions.filter((data) => !selectedOptions.find(({ value }) => data.value === value))}
                                    />
                                )}
                            />
                            {errors[FIELD.team_b] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team_b].message}</small>}
                        </Col>

                        {/* SHIFT B DATE PICKER */}
                        <Col xs={12} sm={6} className={`mb-4 ${!props?.showStartDate ? "d-block" : "d-none"}`}>
                            <Controller
                                control={control}
                                name={FIELD.date_b}
                                rules={{
                                    required: {
                                        value: !props?.showStartDate ? true : false,
                                        message: VALIDATIONS_TEXT.SHIFT_B_DATE_B_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomDatePicker
                                        {...field}
                                        name={FIELD.date_b}
                                        placeholder={"B Shift Monday Days *"}
                                        isClearable={false}
                                        isDependent={false}
                                        isDisabled={true}
                                        excludeDates={excludeDates}
                                        onMonthChange={onMonthChange}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.date_b] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.date_b].message}</small>}
                        </Col>

                        {/* SHIFT C SELECT */}
                        <Col xs={12} sm={6} className="mb-4">
                            <Controller
                                control={control}
                                name={FIELD.team_c}
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.SHIFT_C_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomShiftSelect
                                        {...field}
                                        name={FIELD.team_c}
                                        label="Shift C *"
                                        placeholder={"Shift C *"}
                                        isDisabled={false}
                                        isClearable={true}
                                        isPreFilled={true}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                        options={shiftOptions.filter((data) => !selectedOptions.find(({ value }) => data.value === value))}
                                    />
                                )}
                            />
                            {errors[FIELD.team_c] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team_c].message}</small>}
                        </Col>

                        {/* SHIFT C DATE PICKER */}
                        <Col xs={12} sm={6} className={`mb-4 ${!props?.showStartDate ? "d-block" : "d-none"}`}>
                            <Controller
                                control={control}
                                name={FIELD.date_c}
                                rules={{
                                    required: {
                                        value: !props?.showStartDate ? true : false,
                                        message: VALIDATIONS_TEXT.SHIFT_C_DATE_C_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomDatePicker
                                        {...field}
                                        name={FIELD.date_c}
                                        placeholder={"C Shift Monday Days *"}
                                        isClearable={false}
                                        isDependent={false}
                                        isDisabled={true}
                                        excludeDates={excludeDates}
                                        onMonthChange={onMonthChange}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.date_c] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.date_c].message}</small>}
                        </Col>

                        {/* SHIFT D SELECT */}
                        <Col xs={12} sm={6} className="mb-4">
                            <Controller
                                control={control}
                                name={FIELD.team_d}
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.SHIFT_D_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomShiftSelect
                                        {...field}
                                        name={FIELD.team_d}
                                        label="Shift D *"
                                        placeholder={"Shift D *"}
                                        isDisabled={false}
                                        isClearable={true}
                                        isPreFilled={true}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                        options={shiftOptions.filter((data) => !selectedOptions.find(({ value }) => data.value === value))}
                                    />
                                )}
                            />
                            {errors[FIELD.team_d] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team_d].message}</small>}
                        </Col>

                        {/* SHIFT D DATE PICKER */}
                        <Col xs={12} sm={6} className={`mb-4 ${!props?.showStartDate ? "d-block" : "d-none"}`}>
                            <Controller
                                control={control}
                                name={FIELD.date_d}
                                rules={{
                                    required: {
                                        value: !props?.showStartDate ? true : false,
                                        message: VALIDATIONS_TEXT.SHIFT_D_DATE_D_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomDatePicker
                                        {...field}
                                        name={FIELD.date_d}
                                        placeholder={"D Shift Monday Days *"}
                                        isClearable={false}
                                        isDependent={false}
                                        isDisabled={true}
                                        excludeDates={excludeDates}
                                        onMonthChange={onMonthChange}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.date_d] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.date_d].message}</small>}
                        </Col>

                        {/* START DATE PICKER */}
                        <Col xs={12} sm={6} className={`mb-4 ${props?.showStartDate ? "d-block" : "d-none"}`}>
                            <Controller
                                control={control}
                                name={FIELD.start_date}
                                rules={{
                                    required: {
                                        value: props?.showStartDate ? true : false,
                                        message: VALIDATIONS_TEXT.DATE_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomDatePicker
                                        {...field}
                                        name={FIELD.single_date}
                                        placeholder={props?.placeholder}
                                        isClearable={true}
                                        isDependent={false}
                                        isDisabled={false}
                                        excludeDates={excludeDates}
                                        onMonthChange={onMonthChange}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.start_date] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.start_date].message}</small>}
                        </Col>

                        {/* OT AND CRITERIA SELECT */}
                        <Col xs={12} sm={6} className={`mb-4`}>
                            <Controller
                                control={control}
                                name={FIELD.ot_criteria}
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.OT_CRITERIA_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomOtCriteriaSelect
                                        {...field}
                                        name={FIELD.ot_criteria}
                                        placeholder={"Select OT Selection Criteria *"}
                                        styles=""
                                        isClearable={true}
                                        isPreFilled={false}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.ot_criteria] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.ot_criteria].message}</small>}
                        </Col>

                        {/* SLIDER TOLERANCE SELECT */}
                        <Col xs={12} sm={6} className={`mb-4`}>
                            <Controller
                                control={control}
                                name={FIELD.slider_tolerance}
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.SLIDER_TOLERANCE_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomSliderToleranceSelect
                                        {...field}
                                        name={FIELD.slider_tolerance}
                                        placeholder={"Select Slider Tolerance *"}
                                        styles=""
                                        isClearable={true}
                                        isPreFilled={false}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.slider_tolerance] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.slider_tolerance].message}</small>}
                        </Col>

                        {/* DOUBLE TIME DAY SELECT */}
                        <Col xs={12} sm={6} className={`mb-4`}>
                            <Controller
                                control={control}
                                name={FIELD.double_time_day}
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.DOUBLE_TIME_DAY_REQUIRED,
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomDoubleTimeDaySelect
                                        {...field}
                                        name={FIELD.double_time_day}
                                        placeholder={"Select Double Time Day *"}
                                        styles=""
                                        isClearable={true}
                                        isPreFilled={false}
                                        state={selectedData}
                                        setState={setSelectedData}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                )}
                            />
                            {errors[FIELD.double_time_day] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.double_time_day].message}</small>}
                        </Col>
                    </Row>

                    <Row className="justify-content-end mt-3">
                        <Col xs={6} sm={4} md={2}>
                            <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                        </Col>

                        <Col xs={6} sm={4} md={2}>
                            <Button disabled={isDisabled} type="submit" className='btn-solid w-100'>
                                {isDisabled ? <Loader /> : "Add"}
                            </Button>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12}>
                            <h1>Schedule:</h1>
                            <img className='mt-2 w-100' src={props?.scheduleImage} alt="" />
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default SetWorkSchedule