import api from "../index";
import { apiUrl, constant } from "../../utils/constants";
import axios from "axios";
import { GetTokenLocalStorage } from "../localStorage/localStorage";

export const AddSite = (data) => api.post(`${constant.V1}${apiUrl.sites}/`, data)
export const GetSites = (params) => api.get(`${constant.V1}${apiUrl.sites}/`, { params })
export const GetSitesV2 = (params) => api.get(`${constant.V2}${apiUrl.sites}/`, { params })
export const GetSingleSite = (id) => api.get(`${constant.V1}${apiUrl.sites}/${id}`)
export const EditSite = (id, data) => api.put(`${constant.V1}${apiUrl.sites}/${id}/`, data)
export const GetAvailableSiteAdmins = (params) => api.get(`${constant.V1}${apiUrl.sites}${apiUrl.available_site_admins}`, { params })
export const DeleteSite = (id) => api.delete(`${constant.V1}${apiUrl.sites}/${id}/`)
export const BulkDeleteSite = (ids) => api.delete(`${constant.V1}${apiUrl.sites}${apiUrl.bulk_delete}/`, { data: { ids } })