import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import { ToastContainer } from 'react-toastify'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import { BsCloudArrowDownFill, BsCloudArrowUpFill } from 'react-icons/bs'
import AddUserPopup from '../components/popup/AddUserPopup'
import AddSitePopup from '../components/popup/AddSitePopup'
import { useForm } from 'react-hook-form'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import { error, success } from '../constants/msg'
import { BulkDeleteSite, DeleteSite, GetSingleSite, GetSites } from '../services/sites/sites'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import CustomSearchInput from '../components/forms/CustomSearchInput'
import EditSitePopup from '../components/popup/EditSitePopup'
import { useParams } from 'react-router-dom'
import { GetSingleTeam } from '../services/teams/teams'
import { DeleteTeamUser, BulkDeleteTeamUser } from '../services/teams/teams'

const TeamUsersDetailPage = () => {
    const { id } = useParams()
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [team, setTeam] = useState(null)
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [rows, setRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [columns, setColumns] = useState([
        {
            name: 'User ID',
            selector: rowData => rowData.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: rowData => rowData.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: rowData => rowData.email,
            sortable: true,
        },
    ])

    const getSingleTeam = async () => {
        setIsLoading(true)
        const rows = []
        try {
            const res = await GetSingleTeam(id)
            const data = res.data.data
            data?.members?.forEach((item) => {
                rows.push({
                    id: item?.user_profile.toString() || "-",
                    name: item?.full_name || "-",
                    email: item?.email || "-",
                })
            })
            setTeam(data)
            setRows(rows)
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const deleteTeamUser = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteTeamUser(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteTeamUser(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getSingleTeam()
        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        getSingleTeam()
    }, [])

    return (
        <>
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={deleteTeamUser} />
            <ToastContainer />

            <div className='pages manage-sites-page site-detail-page'>
                <Container>
                    <Row>
                        <Col xs={12} className="pt-2">
                            <h1>User Details of Team: <b className='ms-1 text-grey'>{team?.name}</b></h1>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className="mt-4">
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={true} toggledClearRows={toggledClearRows} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default TeamUsersDetailPage