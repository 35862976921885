import api from "../index";
import { apiUrl, constant } from "../../utils/constants";
import axios from "axios";
import { config } from "../../utils/helper"

export const AddOffTime = (data) => api.post(`${constant.V1}${apiUrl.off_time}/`, data)
export const GetOffTimes = (params) => api.get(`${constant.V1}${apiUrl.off_time}/`, { params })
export const GetSingleOffTime = (id) => api.get(`${constant.V1}${apiUrl.off_time}/${id}/`)
export const EditOffTime = (id, data) => api.put(`${constant.V1}${apiUrl.off_time}/${id}/`, data)
export const DeleteOffTime = (id) => api.delete(`${constant.V1}${apiUrl.off_time}/${id}/`)
export const BulkDeleteOffTime = (ids) => api.delete(`${constant.V1}${apiUrl.off_time}${apiUrl.bulk_delete}/`, { data: { ids } })