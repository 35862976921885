import api from "../index";
import axios from "axios";
import { apiUrl, constant } from "../../utils/constants";

export const Login = (data) => api.post(`${constant.V1}${apiUrl.auth}${apiUrl.login}/`, data)
export const Signup = (data) => api.post(`${constant.V1}${apiUrl.auth}${apiUrl.signup}/`, data)
export const VerifyOtp = (data) => api.post(`${constant.V1}${apiUrl.auth}${apiUrl.verify_otp}/`, data)
export const ForgetPassword = (email) => api.post(`${constant.V1}${apiUrl.auth}${apiUrl.forget_password}/`, email)
export const ResetPassword = (data, token) => axios.post(`${constant.V1}${apiUrl.auth}${apiUrl.reset_password}/`, data, {
    headers: {
        Authorization: 'token ' + token
    }
})
export const ChangePassword = (data) => api.post(`${constant.V1}${apiUrl.auth}${apiUrl.change_password}/`, data)
export const Logout = () => api.post(`${constant.V1}${apiUrl.auth}${apiUrl.logout}/`)
