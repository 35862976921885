import React, { useContext, useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Button, Accordion } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import { ToastContainer } from 'react-toastify'
import { UserData } from '../App'
import CustomInput from '../components/forms/CustomInput'
import Loader from '../components/loader/Loader'
import EditProfilePopup from '../components/popup/EditProfilePopup'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import Assets from '../constants/images'
import { error } from '../constants/msg'
import { GetFaqs, GetPrivacyPolicy, GetTermsAndConditions } from '../services/pages/pages'

const FaqsPage = ({ children }) => {
    const [data, setData] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const getFaqs = async () => {
            setIsLoading(true)
            try {
                const res = await GetFaqs()
                setData(res?.data?.data)
                setIsLoading(false)
            }
            catch (e) {
                setIsLoading(false)
                error(e.response.data.message)
            }
        }

        getFaqs()
    }, [])

    return (
        <div className='pages privacy-policy-page'>
            <Container>
                <Row>
                    <Col xs={12} className="mb-4">
                        <h1>Settings</h1>
                    </Col>

                    <Col md={5} lg={4} xl={3} className="d-none d-md-block">
                        {children}
                    </Col>

                    <Col md={7} lg={8} xl={6}>
                        <div className="content-wrapper">
                            <div className="mb-4">
                                <Row>
                                    <Col xs={12}>
                                        <h1>FAQs</h1>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row>
                                    <Col xs={12}>
                                        {
                                            isLoading ?
                                                <Loader />
                                                :
                                                <Accordion defaultActiveKey={0}>
                                                    {
                                                        data?.length > 0 &&
                                                        data.map((data, index) => (
                                                            <Accordion.Item eventKey={index} className="mb-4">
                                                                <Accordion.Header><b>{data?.question}</b></Accordion.Header>
                                                                <Accordion.Body>
                                                                    <p>{data?.answer}</p>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        ))
                                                    }
                                                </Accordion>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FaqsPage