import React from 'react'
import { STATUS } from '../../utils/constants'

const ThemeStatus = (props) => {
    const returnColorOfStatus = (status) => {
        if (status === STATUS.approved) {
            return "status-approved"
        }
        else {
            return "status-rejected"
        }
    }

    const returnStatusText = (status) => {
        if (status === STATUS.approved) {
            return "Approved"
        }
        else {
            return "Rejected"
        }
    }

    return (
        <div className="theme-status">
            <div className={returnColorOfStatus(props?.status?.status)}>
                <b>{returnStatusText(props?.status?.status)}</b>
            </div>
        </div>
    )
}

export default ThemeStatus