import React, { useState, useEffect } from 'react'
import { GetDepartmentsBySite } from '../../services/departments/departments'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import { ConvertToYearMonthDate } from '../../utils/helper'
import { GetIsEmployeeWorking } from '../../services/calendar-events/calendar-events'

const CustomStatusSelect = (props) => {
    const [options, setOptions] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        // Fetch is employee working by date
        const getIsEmployeeWorking = async () => {
            setIsLoading(true)

            const options = []
            try {
                const apiData = {
                    date: ConvertToYearMonthDate(props?.state?.date),
                    user: props?.state?.user?.value
                }
                const res = await GetIsEmployeeWorking(apiData)
                const data = res.data.data
                data?.options?.forEach(item => {
                    options.push({
                        label: item?.title,
                        value: item?.id
                    })
                })
                setOptions(options)
                setIsLoading(false)
                setIsDisabled(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsDisabled(false)
                error(e.response.data.message)
            }
        }

        if (props?.state?.date) {
            // fetch is employee working only when date is selected
            getIsEmployeeWorking()
        }
    }, [props.state.date])

    useEffect(() => {
        if (!props.getValues(`${FIELD.date}`)) {
            // If no date is selected than clear status select 
            props.setValue(`${FIELD.event}`, null)
        }

        if (!props.getValues(`${FIELD.date}`)) {
            // Disabled the status if there is no date
            setIsDisabled(true)
        }
    }, [props.state])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={props.isClearable}
            options={options}
        />
    )
}

export default CustomStatusSelect