import React, { useState, useContext, useEffect } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../loader/Loader'
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { EyeIcon, LockIcon } from '../../constants/svgs'
import { FIELD, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { ToastContainer } from 'react-toastify'
import { TriggerAllSites, UserData, UserRole } from '../../App'
import { Controller, useForm } from 'react-hook-form'
import CustomInput from '../forms/CustomInput'
import { constant, roles } from '../../utils/constants'
import { Login } from '../../services/auth/auth'
import { SetAuthUserLocalStorage, SetTokenLocalStorage, SetUserRoleLocalStorage } from '../../services/localStorage/localStorage'
import { GenerateDeviceToken } from '../../utils/helper'
import { error } from '../../constants/msg'
import Assets from '../../constants/images'

const SigninForm = () => {
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const [triggerAllSites, setTriggerAllSites] = useContext(TriggerAllSites)

    const setUserTokenRole = (data) => {
        SetTokenLocalStorage(data.token)
        SetAuthUserLocalStorage(data.user)
        setUserData(data.user)
        SetUserRoleLocalStorage(data.role)
        setUserRole(data.role)
    }

    const onSubmit = async (data) => {
        /*
            Business logic for Signin 
            Hit Signin Api then set token, user and user-role detail inside localStorage and UserContext
        */
        setIsDisabled(true)
        try {
            data.device_type = constant.DEVICE_TYPE
            data.device_token = GenerateDeviceToken()
            const res = await Login(data);
            setUserTokenRole(res.data.data)
            setTriggerAllSites(!triggerAllSites)
            setIsDisabled(false)
            navigate("/dashboard")
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    return (
        <div className='auth-card signin-form'>
            <ToastContainer />

            <div className="logo-wrapper mb-30 d-block d-xl-none">
                <img src={Assets.Logo} alt="" />
            </div>

            <div className='header'>
                <h1>Sign in</h1>
                {/*<p className='mt-2'>Don’t have an account? <NavLink to="#">Sign Up</NavLink></p>*/}
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-30'>
                    {/* EMAIL INPUT */}
                    <div>
                        <Controller
                            control={control}
                            name={FIELD.email}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                },
                                maxLength: {
                                    value: VALIDATIONS.EMAIL,
                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                }
                            }}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    isDisabled={false}
                                    controlId={FIELD.email}
                                    name={FIELD.email}
                                    placeholder="Email *"
                                    type="email"
                                    label="Email *"
                                    styles=""
                                />
                            )}
                        />
                        {errors[FIELD.email] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.email].message}</small>}
                    </div>

                    {/* PASSWORD INPUT */}
                    <div className="mt-20">
                        <Controller
                            control={control}
                            name={FIELD.password}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                            }}
                            render={({ field }) => (
                                <div className='password-wrapper'>
                                    <CustomInput
                                        {...field}
                                        isDisabled={false}
                                        controlId={FIELD.password}
                                        name={FIELD.password}
                                        placeholder="Password *"
                                        type={showPassword ? "text" : "password"}
                                        label="Password *"
                                        styles=""
                                    />
                                    <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}><EyeIcon /></span>
                                </div>
                            )}
                        />
                        {errors[FIELD.password] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.password].message}</small>}
                    </div>

                    <div className='text-end mt-20 mb-40'>
                        <NavLink className="w-400" to="/forgot-password">Forget Password</NavLink>
                    </div>

                    <Button type="submit" className='w-100 btn-solid'>
                        {
                            isDisabled ? <Loader /> : "Sign In"
                        }
                    </Button>

                    <div className="footer border-top pt-30 mt-30">
                        <p className='w-300'>
                            Protected by recaptcha and subject to the WTT
                            <NavLink to="/privacy-policy"> Privacy Policy </NavLink>
                            and <NavLink to="/terms-and-conditions"> Terms of Service</NavLink>.
                        </p>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default SigninForm