import React, { useState, useEffect } from 'react'
import { GetSingleTeam, GetTeams, GetUsersByTeam } from '../../services/teams/teams'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import { GetUsers } from '../../services/users/users'

const CustomUserSelect = (props) => {
    const [options, setOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        // Fetch users by team
        const getUsersByTeam = async () => {
            setIsLoading(true)
            const options = []
            try {
                const res = await GetSingleTeam(props?.state?.team?.value)
                const data = res.data.data
                data?.members?.forEach(item => {
                    options.push({
                        label: item?.full_name,
                        value: item?.user_profile
                    })
                })
                setOptions(options)
                setIsLoading(false)
                setIsDisabled(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsDisabled(false)
                error(e.response.data.message)
            }
        }

        if (props.state.team && !props.isEditable) {
            // Fetch users only when team is selected
            getUsersByTeam()
        }
    }, [props.state.team])

    useEffect(() => {
        if (props.isDependent) {
            if (!props.state.team) {
                // If no team is selected than clear user select 
                props.setValue(`${FIELD.user}`, null)
            }

            if (!props.getValues(`${FIELD.department}`) || !props.getValues(`${FIELD.site}`) || !props.getValues(`${FIELD.team}`)) {
                // Disabled the user if there is no team
                setIsDisabled(true)
            }
        }
    }, [props.state])

    useEffect(() => {
        const getUsers = async () => {
            const temp_options = []
            try {
                const params = {
                    unassigned: true,
                    is_paginated: 0,
                    site_id: props.state.site.value,
                }
                const res = await GetUsers(params)
                const data = res.data.data
                data?.forEach((item) => {
                    temp_options.push({
                        label: item?.full_name,
                        value: item?.id
                    })
                })
                setOptions(
                    ...options,
                    temp_options
                )
                setIsDisabled(false)
            }
            catch (e) {
                setIsDisabled(false)
                error(e.response.data.message)
            }
        }

        if (props.state.site && props.isEditable) {
            // isEditable = true only when edit team (multi select)
            getUsers()
        }
    }, [props.state.site])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={props.isClearable}
            options={options}
        />
    )
}

export default CustomUserSelect