import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { FaqsIcon, LockIcon, MyProfileIcon, PrivacyIcon, TermsAndConditionsIcon } from '../../constants/svgs'

const SettingsSidebar = () => {
    return (
        <div className='settings-sidebar'>
            <div className="wrapper">
                <ul>
                    <li className="mb-3">
                        <NavLink to="/settings/my-profile">
                            <div className="d-flex align-items-center">
                                <div className="icon-wrapper me-3">
                                    <MyProfileIcon className='icons' />
                                </div>
                                <div className=''>
                                    <span className='d-inline-block'>My Profile</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>

                    <li className="mb-3">
                        <NavLink to="/settings/change-password">
                            <div className="d-flex align-items-center">
                                <div className="icon-wrapper me-3">
                                    <LockIcon className='icons' />
                                </div>
                                <div className=''>
                                    <span className='d-inline-block'>Change Password</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>

                    <li className="mb-3">
                        <NavLink to="/settings/terms-and-conditions">
                            <div className="d-flex align-items-center">
                                <div className="icon-wrapper me-3">
                                    <TermsAndConditionsIcon className='icons' />
                                </div>
                                <div className=''>
                                    <span className='d-inline-block'>Terms and Conditions</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>

                    <li className="mb-3">
                        <NavLink to="/settings/privacy-policy">
                            <div className="d-flex align-items-center">
                                <div className="icon-wrapper me-3">
                                    <PrivacyIcon className='icons' />
                                </div>
                                <div className=''>
                                    <span className='d-inline-block'>Privacy Policy</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>

                    <li className="mb-3">
                        <NavLink to="/settings/faqs">
                            <div className="d-flex align-items-center">
                                <div className="icon-wrapper me-3">
                                    <FaqsIcon className='icons' />
                                </div>
                                <div className=''>
                                    <span className='d-inline-block'>FAQs</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SettingsSidebar