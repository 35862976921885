export const VALIDATIONS = {
    NAME: 32,
    POST_MIN: 3,
    POST_MAX: 32,
    EMAIL: 32,
    SUBJECT: 32,
    PASSWORD_MIN: 8,
    PASSWORD_MAX: 15,
    PHONE_MIN: 11,
    PHONE_MAX: 15,
    ADDRESS_MAX: 200,
    DESCRIPTION_MAX: 255,
    SITE_LOCATION_MAX: 32,
    OT_HOURS_MAX: 99,

    COUNTRY_MAX: 200,
    CITY_MAX: 200,
    STATE_MAX: 55,
    ZIP_CODE: 18,

    POST_MAX: 10,

    VERIFICATION_CODE: 4,
    CARD_NUMBER: 16,
    CVV: 3,
}

export const VALIDATIONS_TEXT = {
    NAME_REQUIRED: "Name is required",
    NAME_MAX: "Max length is " + VALIDATIONS.NAME,

    EMAIL_REQUIRED: "Email is required",
    EMAIL_MAX: "Max length is " + VALIDATIONS.EMAIL,
    EMAIL_FORMAT: "Invalid email format",

    CONFIRM_NEW_PASSWORD_REQUIRED: "Confirm new password is required",
    CURRENT_PASSWORD_REQUIRED: "Current password is required",
    NEW_PASSWORD_REQUIRED: "New password is required",
    PASSWORD_REQUIRED: "Password is required",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
    PASSWORD_FORMAT: "Password must be at-least 8 characters long with one upper case.",
    PASSWORD_MIN: "Min length is " + VALIDATIONS.PASSWORD_MIN,
    PASSWORD_MAX: "Max length is " + VALIDATIONS.PASSWORD_MAX,

    SITE_REQUIRED: "Site is required",
    DEPARTMENT_REQUIRED: "Department is required",
    TEAM_REQUIRED: "Team is required",
    USER_REQUIRED: "User is required",
    ROLE_REQUIRED: "Role is required",
    SITE_ADMIN_REQUIRED: "Site admin is required",

    SITE_LOCATION_REQUIRED: "Site location is required",
    SITE_LOCATION_MAX: "Max length is " + VALIDATIONS.SITE_LOCATION_MAX,

    EVENT_STATUS_REQUIRED: "Event status is required",
    EVENT_TYPE_REQUIRED: "Event type is required",

    SCHEDULE_REQUIRED: "Schedule is required",
    PAY_TYPE_REQUIRED: "Pay type is required",
    OT_REQUIRED: "OT is required",
    SCHEDULE_DETAIL_REQUIRED: "Schedule detail dates is required",

    POST_1_REQUIRED: "Post 1 is required",
    POST_2_REQUIRED: "Post 2 is required",
    POST_3_REQUIRED: "Post 3 is required",
    POST_4_REQUIRED: "Post 4 is required",
    POST_5_REQUIRED: "Post 5 is required",
    POST_6_REQUIRED: "Post 6 is required",
    POST_NAME: "Post name is required",
    POST_TYPE: "Post type is required",

    POST_MIN: "Min length is " + VALIDATIONS.POST_MIN,
    POST_MAX: "Max length is " + VALIDATIONS.POST_MAX,

    OT_HOURS_REQUIRED: "OT hours is required",
    OT_HOURS_MAX: "Max length is " + VALIDATIONS.OT_HOURS_MAX,

    OVERALL_PERCENTAGE_OFF_REQUIRED: "Overall off % is required",
    OFF_ITEM_REQUIRED: "Off item is required",
    YEARS_OF_SERVICES_REQUIRED: "Years of services is required",
    COMPETENCY_REQUIRED: "Competency is required",

    DURATION_REQUIRED: "Duration is required",
    RECENT_EVENT_REQUIRED: "Recent event is required",
    DATE_REQUIRED: "Date is required",
    START_DATE_REQUIRED: "Start date is required",
    END_DATE_REQUIRED: "End date is required",
    FILE_REQUIRED: "File is required",

    START_DATE: "Start date should be less than end date",
    END_DATE: "End date should be greater than start date",

    SHIFT_A_REQUIRED: "Shift A is required",
    SHIFT_B_REQUIRED: "Shift B is required",
    SHIFT_C_REQUIRED: "Shift C is required",
    SHIFT_D_REQUIRED: "Shift D is required",

    SHIFT_A_DATE_A_REQUIRED: "Shift A date is required",
    SHIFT_B_DATE_B_REQUIRED: "Shift B date is required",
    SHIFT_C_DATE_C_REQUIRED: "Shift C date is required",
    SHIFT_D_DATE_D_REQUIRED: "Shift D date is required",

    SHIFT_A_DAYS_REQUIRED: "Shift A days is required",
    SHIFT_B_DAYS_REQUIRED: "Shift B days is required",
    SHIFT_C_DAYS_REQUIRED: "Shift C days is required",
    SHIFT_D_DAYS_REQUIRED: "Shift D days is required",

    SLIDER_TOLERANCE_REQUIRED: "Slider tolerance is required",
    OT_CRITERIA_REQUIRED: "Ot criteria is required",
    DOUBLE_TIME_DAY_REQUIRED: "Double time day is required",
}

export const FIELD = {
    full_name: "full_name",
    email: "email",
    role: "role",
    site: "site",
    department: "department",
    team: "team",
    user: "user",
    site_admin: "site_admin",
    location: "location",
    pay_type: "pay_type",
    ot_hours: "ot_hours",
    recent_events: "recent_events",
    date: "date",
    start_date: "start_date",
    end_date: "end_date",
    event: "event",
    event_status: "event_status",
    schedule: "schedule",
    durations: "durations",
    ot: "ot",
    title: "title",
    type: "type",
    off_item: "off_item",
    overall_percentage_off: "overall_percentage_off",
    years_of_service: "yearsofservice",
    duration: "duration",
    min_year: "min_year",
    max_year: "max_year",
    user_profiles: "user_profiles",
    schedule_detail: "scheduledetail",
    competency: "competency",
    is_emt_qualified: "is_emt_qualified",
    is_ert_qualified: "is_ert_qualified",
    listing: "listing",
    user_profile: "user_profile",
    site_chart: "site_chart",
    department_chart: "department_chart",
    department_name: "department_name",
    slider_tolerance: "slider_tolerance",
    ot_criteria: "ot_criteria",
    double_time_day: "double_time_day",
    single_date:"single_date",

    post_1: "post_1",
    post_2: "post_2",
    post_3: "post_3",
    post_4: "post_4",
    post_5: "post_5",
    post_6: "post_6",

    team_a: "team_a",
    team_b: "team_b",
    team_c: "team_c",
    team_d: "team_d",

    date_a: "date_a",
    date_b: "date_b",
    date_c: "date_c",
    date_d: "date_d",

    password: "password",
    old_password: "old_password",
    new_password: "new_password",
    confirm_password: "confirm_password",
}

export const KEYS = [FIELD.role, FIELD.site, FIELD.department, FIELD.team, FIELD.user, FIELD.event, FIELD.schedule, FIELD.type, FIELD.pay_type, FIELD.double_time_day, FIELD.slider_tolerance, FIELD.team_a, FIELD.team_b, FIELD.team_c, FIELD.team_d, FIELD.ot_criteria]

export const SPECIAL_CHARACTER_ARRAY = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ",", ">", ".", "?", "/"
]

export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ">", ".", "?", "/"
]

export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = [
    "e", "E", "-", "+", "."
]