import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import { ToastContainer } from 'react-toastify'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import AddSetupPostInformationPopup from '../components/popup/AddSetupPostInformationPopup'
import { useForm } from 'react-hook-form'
import { error, success } from '../constants/msg'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import { BulkDeleteOffTime, DeleteOffTime, GetOffTimes } from '../services/off-time/off-time'
import EditSetOffItemInformationPopup from '../components/popup/EditSetOffItemInformationPopup'
import AddSetOffItemInformationPopup from '../components/popup/AddSetOffItemInformationPopup'

const SetOffItemInformationPage = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [addSetOffItemInformationPopup, setAddSetOffItemInformationPopup] = useState(false)
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [editSetOffItemInformationPopup, setEditSetOffItemInformationPopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([
        {
            name: 'Off Item',
            selector: rowData => rowData.off_item,
            sortable: true,
        },
        {
            name: 'Overall % Off',
            selector: rowData => rowData.overall_percentage_off,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (rowData, row) => <ThemeDataTableAction link="" row={row} rowData={rowData} setEditPopup={setEditSetOffItemInformationPopup} setSelectedRow={setSelectedRow} viewAction={false} />
        },
    ])

    const getOffTimes = async () => {
        const rows = []
        setIsLoading(true)
        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
            }
            const res = await GetOffTimes(params)
            const data = res.data
            data?.data?.forEach((item) => {
                rows.push({
                    id: item?.id.toString() || "-",
                    off_item: item?.off_item.toString() || "-",
                    overall_percentage_off: item?.overall_percentage_off.toString() || "-",
                })
            })
            setRows(rows)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const deleteOffTime = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteOffTime(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteOffTime(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getOffTimes()
        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        getOffTimes()
    }, [page.currentPage])

    return (
        <>
            <AddSetOffItemInformationPopup addSetOffItemInformationPopup={addSetOffItemInformationPopup} setAddSetOffItemInformationPopup={setAddSetOffItemInformationPopup} rows={rows} setRows={setRows} />
            <EditSetOffItemInformationPopup editSetOffItemInformationPopup={editSetOffItemInformationPopup} setEditSetOffItemInformationPopup={setEditSetOffItemInformationPopup} getOffTimes={getOffTimes} selectedRow={selectedRow} />
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={deleteOffTime} />
            <ToastContainer />

            <div className='setoff-time-information-page'>
                <Container className='p-0'>
                    <Row>
                        <Col xs={12} sm={8} md={6} className="pt-2">
                            <h1>Set Off Item Information</h1>
                        </Col>

                        <Col xs={12} sm={4} md={6} className="">
                            <Row className="justify-content-end">
                                <Col xs={12} sm={12} md={8} lg={4} xl={3} className="mb-4">
                                    <Button onClick={() => setAddSetOffItemInformationPopup(true)} className='mt-4 mt-sm-0 w-100 btn-solid'>+ Add New</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col xs={12}>
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={true} toggledClearRows={toggledClearRows} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SetOffItemInformationPage