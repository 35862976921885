import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import Loader from '../loader/Loader'
import { FIELD, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomInput from '../forms/CustomInput'
import { CleanAndFormatData } from '../../utils/helper'
import { AddOffTime } from '../../services/off-time/off-time'
import { error, success } from '../../constants/msg'
import { ToastContainer } from 'react-toastify'
import ClearFormPopup from './ClearFormPopup'

const AddSetOffItemInformationPopup = ({ addSetOffItemInformationPopup, setAddSetOffItemInformationPopup, rows, setRows }) => {
    const { register, handleSubmit, watch, reset, getValues, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)

    const clearForm = () => {
        reset({
            [FIELD.off_item]: "",
            [FIELD.overall_percentage_off]: "",
        })
        setClearFormPopup(false)
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = CleanAndFormatData(data)
        try {
            const res = await AddOffTime(formData)
            const obj = {
                id: res?.data?.data?.id || "-",
                off_item: res?.data?.data?.off_item || "-",
                overall_percentage_off: res?.data?.data?.overall_percentage_off || "-",
            }
            setRows([obj, ...rows])
            success(res.data.message)
            setAddSetOffItemInformationPopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        // clear form when popup open or close
        clearForm()
    }, [addSetOffItemInformationPopup])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />
            <ToastContainer />

            <Modal
                className='theme-popup add-setoff-item-information-popup'
                show={addSetOffItemInformationPopup}
                onHide={() => setAddSetOffItemInformationPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Add New Item</h1>
                                        <span className='times-icon' onClick={() => setAddSetOffItemInformationPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* OFF ITEM INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.off_item}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.OFF_ITEM_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.off_item}
                                                    name={FIELD.off_item}
                                                    placeholder="Off Item *"
                                                    type="text"
                                                    label="Off Item *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.off_item] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.off_item].message}</small>}
                                    </Col>

                                    {/* OVERALL OFF % INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.overall_percentage_off}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.OVERALL_PERCENTAGE_OFF_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.overall_percentage_off}
                                                    name={FIELD.overall_percentage_off}
                                                    placeholder="Overall % Off *"
                                                    type="number"
                                                    label="Overall % Off *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.overall_percentage_off] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.overall_percentage_off].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button type="submit" disabled={isDisabled} className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Add"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddSetOffItemInformationPopup