import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomSiteAdminSelect from '../forms/CustomSiteAdminSelect'
import { FIELD, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomInput from '../forms/CustomInput'
import Loader from '../loader/Loader'
import { CleanAndFormatData, GetOnlyMondays } from '../../utils/helper'
import { error, success } from '../../constants/msg'
import { AddSite } from '../../services/sites/sites'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import CustomDatePicker from '../forms/CustomDatePicker'
import { AddSchedule } from '../../services/schedules/schedules'
import { ToastContainer } from 'react-toastify'
import ClearFormPopup from './ClearFormPopup'

const CreateShiftSchedulePopup = ({ createShiftSchedulePopup, setCreateShiftSchedulePopup, rows, setRows }) => {
    const { register, handleSubmit, watch, reset, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [excludeDates, setExcludeDates] = useState(GetOnlyMondays(new Date()))
    const [isDisabled, setIsDisabled] = useState(false)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null, date: null
    })

    const onMonthChange = (value) => {
        const excludesDates = GetOnlyMondays(value)
        setExcludeDates(excludesDates)
    }

    const clearForm = () => {
        reset({
            [FIELD.site]: null,
            [FIELD.date]: null,
            [FIELD.durations]: ""
        })
        setSelectedData({ role: null, site: null, department: null, team: null, user: null, date: null })
        setClearFormPopup(false)
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        try {
            const formData = CleanAndFormatData(data)
            const res = await AddSchedule(formData)
            const obj = {
                id: res?.data?.data?.id || "-",
                duration: res?.data?.data?.durations_display || "-",
                schedule_period: res?.data?.data?.start_date + " - " + res?.data?.data?.end_date || "-",
                site_name: res?.data?.data.site_name || "-",
            }
            setRows([obj, ...rows])
            setIsDisabled(false)
            setCreateShiftSchedulePopup(false)
            success(res.data.message)
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        clearForm()
    }, [createShiftSchedulePopup])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />

            <Modal
                className='theme-popup create-shift-schedule-popup'
                show={createShiftSchedulePopup}
                onHide={() => setCreateShiftSchedulePopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Create Shift Schedule</h1>
                                        <span className='times-icon' onClick={() => setCreateShiftSchedulePopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* SITE SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.site}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteSelect
                                                    {...field}
                                                    name={FIELD.site}
                                                    placeholder={"Select Site *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                                    </Col>

                                    {/* WEEK'S INPUT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.durations}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DURATION_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.durations}
                                                    name={FIELD.durations}
                                                    placeholder="Week(s) *"
                                                    type="number"
                                                    label="Week(s) *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.durations] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.durations].message}</small>}
                                    </Col>

                                    {/* DATE PICKER */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.start_date}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DATE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomDatePicker
                                                    {...field}
                                                    name={FIELD.start_date}
                                                    placeholder={"Select Date *"}
                                                    isClearable={true}
                                                    isDisabled={false}
                                                    isDependent={false}
                                                    state={selectedData}
                                                    excludeDates={excludeDates}
                                                    onMonthChange={onMonthChange}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.start_date] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.start_date].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button disabled={isDisabled} type="submit" className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Add"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateShiftSchedulePopup