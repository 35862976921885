import React, { useState, useEffect } from 'react'
import { GetSites } from '../../services/sites/sites'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import DatePicker from 'react-datepicker'
import { ConvertToYearMonthDate, GetOnlyMondays } from '../../utils/helper'

const CustomDatePicker = (props) => {
    const handleChange = (value) => {
        if (props.name === FIELD.date) {
            if (value) {
                props.setState({
                    ...props.state,
                    date: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    date: null
                })
            }
        }
        else if (props.name === FIELD.start_date) {
            if (value) {
                props.setState({
                    ...props.state,
                    start_date: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    start_date: null
                })
            }
        }
        else if (props.name === FIELD.end_date) {
            if (value) {
                props.setState({
                    ...props.state,
                    end_date: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    end_date: null
                })
            }
        }
    }

    useEffect(() => {
        if (props.isDependent && !props.getValues(`${FIELD.user}`)) {
            // If no user is selected than clear dates select 
            props.setValue(`${FIELD.date}`, null)
            props.setValue(`${FIELD.start_date}`, null)
            props.setValue(`${FIELD.end_date}`, null)
        }
    }, [props.state])

    return (
        <DatePicker
            {...props}
            minDate={new Date()}
            placeholderText={props.placeholder}
            isClearable={props.isClearable}
            autoComplete="off"
            showTimeSelect={false}
            disabled={props.isDisabled}
            {...props.excludeDates && { excludeDates: props.excludeDates }}
            {...props.onMonthChange && { onMonthChange: props.onMonthChange }}
            selected={props.value}
            onChange={(value) => {
                props.onChange(value)
                handleChange(value)
            }}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
        />
    )
}

export default CustomDatePicker