import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomInput from '../forms/CustomInput'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'

const ClearFormPopup = ({ clearFormPopup, setClearFormPopup, clearForm }) => {
    return (
        <>
            <Modal
                className='theme-popup clear-form-popup'
                show={clearFormPopup}
                onHide={() => setClearFormPopup(false)}
                size="sm"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Clear Record</h1>
                                        <span className='times-icon' onClick={() => setClearFormPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='mt-4'>
                            <Row>
                                <Col xs={12}>
                                    <p>Are you sure you want to clear this record?</p>
                                </Col>
                            </Row>
                        </div>

                        <Row className="justify-content-end mt-4">
                            <Col xs={6} sm={4}>
                                <Button className='btn-outline w-100' onClick={() => setClearFormPopup(false)}>No</Button>
                            </Col>

                            <Col xs={6} sm={4}>
                                <Button className='btn-solid w-100' onClick={clearForm}>Yes</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ClearFormPopup