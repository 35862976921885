import api from "../index";
import { apiUrl, constant } from "../../utils/constants";
import axios from "axios";
import { config } from "../../utils/helper"

export const AddVacation = (data) => api.post(`${constant.V1}${apiUrl.vacation}/`, data)
export const GetVacations = (params) => api.get(`${constant.V1}${apiUrl.vacation}/`, { params })
export const GetSingleVacation = (id) => api.get(`${constant.V1}${apiUrl.vacation}/${id}/`)
export const EditVacation = (id, data) => api.put(`${constant.V1}${apiUrl.vacation}/${id}/`, data)
export const DeleteVacation = (id) => api.delete(`${constant.V1}${apiUrl.vacation}/${id}/`)
export const BulkDeleteVacation = (ids) => api.delete(`${constant.V1}${apiUrl.vacation}${apiUrl.bulk_delete}/`, { data: { ids } })