import React, { useContext, useRef, useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomInput from '../forms/CustomInput'
import { FIELD, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { error, success } from '../../constants/msg'
import { EditProfile, GetProfile } from '../../services/me/me'
import { SetAuthUserLocalStorage } from '../../services/localStorage/localStorage'
import { UserData } from "../../App"
import Loader from '../loader/Loader'

const EditProfilePopup = ({ editProfilePopup, setEditProfilePopup }) => {
    const [userData, setUserData] = useContext(UserData)
    const [isDisabled, setIsDisabled] = useState(false)
    const imageRef = useRef()
    const [file, setFile] = useState({
        file: null,
        file_url: null
    })

    const { register, handleSubmit, watch, reset, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        setIsDisabled(true)
        try {
            const formData = new FormData()
            formData.append("full_name", data.full_name)
            formData.append("email", data.email)
            if (file.file) {
                formData.append("image", file.file)
            }
            const res = await EditProfile(formData)
            SetAuthUserLocalStorage(res.data.data)
            setUserData(res.data.data)
            imageRef.current.value = null
            setFile({
                ...file,
                file: null, 
            })
            setIsDisabled(false)
            setEditProfilePopup(false)
            success(res.data.message)
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        const getProfile = async () => {
            try {
                const res = await GetProfile()
                const data = res.data.data
                setFile({
                    ...file,
                    file_url: data?.image
                })
                reset({
                    [FIELD.full_name]: data?.full_name || "-",
                    [FIELD.email]: data?.email,
                    [FIELD.role]: data?.role_name,
                })
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        getProfile()
    }, [editProfilePopup])

    return (
        <>
            <Modal
                className='theme-popup edit-profile-popup'
                show={editProfilePopup}
                onHide={() => setEditProfilePopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Edit Profile</h1>
                                        <span className='times-icon' onClick={() => setEditProfilePopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='mt-4'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* IMAGE FILE INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <label className="profile-wrapper" htmlFor="post-profile">
                                            <input
                                                className='d-none'
                                                type="file"
                                                accept="image/*"
                                                id="post-profile"
                                                ref={imageRef}
                                                onChange={(e) => {
                                                    setFile({
                                                        file: e.target.files[0],
                                                        file_url: URL.createObjectURL(e.target.files[0])
                                                    })
                                                }}
                                            />
                                            <img src={file.file_url ? file.file_url : Assets.ProfilePlaceholderImage} onError={e => e.target.src = Assets.ProfilePlaceholderImage} alt="" />
                                        </label>
                                    </Col>

                                    {/* FULL NAME INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.full_name}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.NAME_REQUIRED,
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.NAME,
                                                    message: VALIDATIONS_TEXT.NAME_MAX
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.full_name}
                                                    name={FIELD.full_name}
                                                    placeholder="Full Name *"
                                                    type="text"
                                                    label="Full Name *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.full_name] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.full_name].message}</small>}
                                    </Col>

                                    {/* EMAIL INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.email}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.EMAIL,
                                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                                },
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={true}
                                                    controlId={FIELD.email}
                                                    name={FIELD.email}
                                                    placeholder="Email *"
                                                    type="email"
                                                    label="Email *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.email] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.email].message}</small>}
                                    </Col>

                                    {/* ROLE INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.role}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.ROLE_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={true}
                                                    controlId={FIELD.role}
                                                    name={FIELD.role}
                                                    placeholder="Role *"
                                                    type="text"
                                                    label="Role *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.email] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.email].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-4">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setEditProfilePopup(false)}>Cancel</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button type='submit' disabled={isDisabled} className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Update"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditProfilePopup