import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { NavLink } from 'react-router-dom'
import { GetBackgroundColor } from '../../utils/helper'
import ThemeChart from './ThemeChart'

const ScheduleRunBox = (props) => {
    const [overTimeLabels, setOverTimeLabels] = useState([])
    const [overTimeDatasets, setOverTimeDatasets] = useState([])

    const [shiftEfficiencyLabels, setShiftEfficiencyLabels] = useState([])
    const [shiftEfficiencyDatasets, setShiftEfficiencyDatasets] = useState([])

    const overTimeData = props?.overTimeData
    const shiftEfficiencyData = props?.shiftEfficiencyData

    useEffect(() => {
        const overTimeLabels = []
        const shiftEfficiencyLabels = []

        const overTimeDatasets = []
        const shiftEfficiencyDatasets = []

        overTimeData?.team_data?.forEach((item, index) => {
            overTimeLabels.push(item?.team_name)
            overTimeDatasets.push({
                label: item?.team_name,
                data: [item?.avg_overtime],
                backgroundColor: GetBackgroundColor(index),
            })
        })

        shiftEfficiencyData?.team_data?.forEach((item, index) => {
            shiftEfficiencyLabels.push(item?.team_name)
            shiftEfficiencyDatasets.push({
                label: item?.team_name,
                data: [item?.value],
                backgroundColor:  GetBackgroundColor(index),
            })
        })

        setOverTimeLabels(overTimeLabels)
        setOverTimeDatasets(overTimeDatasets)

        setShiftEfficiencyLabels(overTimeLabels)
        setShiftEfficiencyDatasets(overTimeDatasets)
    }, [props])

    return (
        <div className='schedule-run-box'>
            <Row>
                <Col xs={12}>
                    <h1>{props?.title}</h1>
                </Col>

                <Col xs={12} xl={6}>
                    <div className='info-wrapper mt-3'>
                        <p>Shift Efficiency %</p>
                        <h2 className='my-2'>
                            {props.isLoading ? <Skeleton count={1} /> : `Avg ${overTimeData?.avg + " %" || 'N/A'}`}
                        </h2>
                        <span><span>{props.isLoading ? <Skeleton count={1} /> : overTimeData?.last_week_avg + " %" || "N/A"}</span> vs last week</span>
                    </div>

                    <div className="chart-wraper mt-5">
                        <ThemeChart labels={overTimeLabels} datasets={overTimeDatasets} />
                    </div>
                </Col>

                <Col xs={12} xl={6}>
                    <div className='info-wrapper mt-3'>
                        <p>Overtime %</p>
                        <h2 className='my-2'>
                            {props.isLoading ? <Skeleton count={1} /> : `Avg ${shiftEfficiencyData?.avg + " %" || 'N/A'}`}
                        </h2>
                        <span><span>{props.isLoading ? <Skeleton count={1} /> : shiftEfficiencyData?.last_week_avg + " %" || "N/A"}</span> vs last week</span>
                    </div>

                    <div className="chart-wraper mt-5">
                        <ThemeChart labels={shiftEfficiencyLabels} datasets={shiftEfficiencyDatasets} />
                    </div>
                </Col>
            </Row>

            <Row className='mt-5 justify-content-center'>
                <Col xs={12} sm={5} md={12} lg={6} xl={4}>
                    <NavLink to="/schedule-report" className='w-100 btn-solid'>Update Charts</NavLink>
                </Col>

                <Col xs={12} sm={5} md={12} lg={6} xl={4} className="mt-3 mt-sm-0 mt-md-3 mt-lg-0">
                    <NavLink to="#" className='w-100 btn-solid'>Show Last Schedule</NavLink>
                </Col>
            </Row>
        </div>
    )
}

export default ScheduleRunBox