import React from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'

const CustomInput = (props) => {
    return (
        <FloatingLabel label={props.label}>
            <Form.Control
                {...props}
                disabled={props.isDisabled}
                type={props.type}
                placeholder={props.placeholder}
                onChange={(value) => props.onChange(value)}
            />
        </FloatingLabel>
    )
}

export default CustomInput