import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import InformationBox from '../components/dashboard/InformationBox'
import ScheduleRunBox from '../components/dashboard/ScheduleRunBox'
import ThemeChart from '../components/dashboard/ThemeChart'
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import DatePicker from "react-datepicker";
import { NavLink } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import CustomSiteSelect from '../components/forms/CustomSiteSelect'
import { error } from '../constants/msg'
import { GetSingleSite } from '../services/sites/sites'
import CustomDepartmentSelect from '../components/forms/CustomDepartmentSelect'
import { GetSchedulesAvgOvertime, GetSchedulesShiftEfficiency } from '../services/schedules/schedules'
import Loader from '../components/loader/Loader'
import { GetSingleDepartment } from '../services/departments/departments'

const HomePage = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const array = [
        {
            id: "company",
            name: "Company",
            title: "Last Schedule Run - Company Average"
        },
        {
            id: "site",
            name: "Site",
            title: "Last Schedule Run - Site Average"
        },
        {

            id: "department",
            name: "Department",
            title: "Last Schedule Run - Department Average"
        },
    ]
    const [isLoading, setIsLoading] = useState(true)
    const [siteData, setSiteData] = useState(null)
    const [departmentData, setDepartmentData] = useState(null)
    const [overTimeData, setIOverTimeData] = useState([])
    const [shiftEfficiencyData, setShiftEfficiencyData] = useState([])
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null
    })
    const site = watch(FIELD.site)
    const department = watch(FIELD.department)

    useEffect(() => {
        const getSingleSite = async () => {
            try {
                const res = await GetSingleSite(site?.value)
                setSiteData(res?.data?.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        if (site) {
            // if site is selected than call only
            getSingleSite()
        }
    }, [site])

    useEffect(() => {
        setIsLoading(true)
        const getSchedulesAvgOvertime = async () => {
            try {
                const params = {
                    site_id: site?.value,
                    department_id: department?.value
                }
                const res = await GetSchedulesAvgOvertime(params)
                setIOverTimeData(res?.data?.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        const getSchedulesShiftEfficiency = async () => {
            try {
                const params = {
                    site_id: site?.value,
                    department_id: department?.value
                }
                const res = await GetSchedulesShiftEfficiency(params)
                setShiftEfficiencyData(res?.data?.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        if (site && department) {
            getSchedulesAvgOvertime()
            getSchedulesShiftEfficiency()
            setTimeout(() => {
                setIsLoading(false)
            }, 1200)
        }
    }, [site, department])

    useEffect(() => {
        if (siteData && department) {
            siteData?.departments.forEach((item) => {
                if (item?.id == department?.value) {
                    setDepartmentData(item)
                }
            })
        }
    }, [siteData, department])

    return (
        <div className='pages home-page'>
            <Container>
                <Row>
                    <Col xl={3} className="pt-2">
                        <h1>Last Schedule Run</h1>
                    </Col>

                    <Col xl={9}>
                        <Row className='justify-content-xl-end mt-4 mt-xl-0'>
                            <Col xs={12} sm={6} md={3} xl={3} className="mb-4">
                                <NavLink to="/schedule-report" className='w-100 btn-solid'>Update Charts</NavLink>
                            </Col>

                            <Col xs={12} sm={6} md={3} xl={3} className="mb-4">
                                <NavLink to="#" className='w-100 btn-solid'>Show Last Schedule</NavLink>
                            </Col>

                            {/* SITE SELECT */}
                            <Col xs={12} sm={6} md={3} xl={3} className="mb-4">
                                <Controller
                                    control={control}
                                    name={FIELD.site}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                        }
                                    }}
                                    render={({ field }) => (
                                        <CustomSiteSelect
                                            {...field}
                                            name={FIELD.site}
                                            placeholder={"Select Site"}
                                            styles="small-select"
                                            isClearable={false}
                                            isPreFilled={true}
                                            state={selectedData}
                                            setState={setSelectedData}
                                            setValue={setValue}
                                            getValues={getValues}
                                        />
                                    )}
                                />
                                {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                            </Col>

                            {/* DEPARTMENT SELECT */}
                            <Col xs={12} sm={6} md={3} xl={3} className="mb-4">
                                <Controller
                                    control={control}
                                    name={FIELD.department}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                        }
                                    }}
                                    render={({ field }) => (
                                        <CustomDepartmentSelect
                                            {...field}
                                            name={FIELD.department}
                                            placeholder={"Select Department"}
                                            styles="small-select"
                                            isClearable={false}
                                            isPreFilled={true}
                                            state={selectedData}
                                            setState={setSelectedData}
                                            setValue={setValue}
                                            getValues={getValues}
                                        />
                                    )}
                                />
                                {errors[FIELD.department] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department].message}</small>}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {
                    site && !department ?
                        <Row>
                            <Col xs={12}>
                                <div className='d-flex align-items-center justify-content-center flex-column mt-5'>
                                    <h1 className='mt-5'>Please Select Site And Department</h1>
                                </div>
                            </Col>
                        </Row>
                        :
                        <>

                            <Row className="mt-4">
                                <Col xs={6} sm={6} md={6} lg={6} xl={3} className="mb-4">
                                    <StatsCard heading="OT Criteria" value={siteData?.details?.ot_criteria_display} />
                                </Col>

                                <Col xs={6} sm={6} md={6} lg={6} xl={3} className="mb-4">
                                    <StatsCard heading="Slider Tolerance" value={siteData?.details?.slider_tolerance} />
                                </Col>

                                <Col xs={6} sm={6} md={6} lg={6} xl={3} className="mb-4">
                                    <StatsCard heading="Current Schedule" value={siteData?.details?.schedule_type_display} />
                                </Col>

                                <Col xs={6} sm={6} md={6} lg={6} xl={3} className="mb-4">
                                    <StatsCard heading="Double Timeday" value={siteData?.details?.double_time_day} />
                                </Col>
                            </Row>

                            <Row>
                                {
                                    array.map((data, index) => (
                                        <>
                                            <Col key={index} xs={12} sm={12} md={6} lg={6} xl={4} className="mb-4">
                                                <InformationBox
                                                    isLoading={isLoading}
                                                    id={data?.id}
                                                    name={data?.name}
                                                    siteData={siteData}
                                                    departmentData={departmentData}
                                                />
                                            </Col>

                                            <Col xs={12} sm={12} md={6} lg={6} xl={8} className="mb-4">
                                                <ScheduleRunBox
                                                    isLoading={isLoading}
                                                    title={data?.title}
                                                    overTimeData={overTimeData[0]}
                                                    shiftEfficiencyData={shiftEfficiencyData[0]}
                                                />
                                            </Col>
                                        </>
                                    ))
                                }
                            </Row>
                        </>
                }
            </Container>
        </div>
    )
}

export default HomePage