import React, { useState, useEffect } from 'react'
import { GetAvailableSiteAdmins, GetSites } from '../../services/sites/sites'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'

const CustomSiteAdminSelect = (props) => {
    const [options, setOptions] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        const getAvailableSiteAdmins = async () => {
            const options = []
            setIsLoading(true)
            setIsDisabled(true)

            try {
                const params = {}
                const res = await GetAvailableSiteAdmins(params)
                const data = res.data.data
                // data?.forEach(item => {
                //     options.push({
                //         label: item?.location,
                //         value: item.id
                //     })
                // })
                setOptions(options)
                setIsLoading(false)
                setIsDisabled(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsDisabled(false)
                error(e.response.data.message)
            }
        }

        getAvailableSiteAdmins()
    }, [])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={props.isClearable}
            options={options}
        />
    )
}

export default CustomSiteAdminSelect