import React, { useContext, useEffect, useState } from 'react'
import Assets from '../../constants/images'
import { Form, NavDropdown, Nav, Button, Navbar, Container, Row, Col, Offcanvas } from "react-bootstrap"
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { HomeIcon, CalendarEventIcon, ShiftScheduleIcon, SetupIcon, CompetencyIcon, UserIcon, SettingIcon, LogoutIcon, MyProfileIcon, LockIcon, TermsAndConditionsIcon, PrivacyIcon, FaqsIcon } from "../../constants/svgs"
import { FaBell } from "react-icons/fa"
import { BsThreeDotsVertical } from "react-icons/bs"
import { BiChevronDown } from "react-icons/bi"
import { UserData, UserRole } from '../../App'
import NotificationPopup from '../../components/popup/NotificationPopup'
import LogoutPopup from '../../components/popup/LogoutPopup'
import { roles } from '../../utils/constants'
import { HandleShow } from '../../utils/helper'
import { error } from '../../constants/msg'
import { GetNotifications } from '../../services/notifications/notifications'

const DashboardNavbar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const [notificationPopup, setNotificationPopup] = useState(false)
    const [logoutPopup, setLogoutPopup] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [isAnyUnread, setIsAnyUnread] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const getNotifications = async () => {
            setIsLoading(true)
            try {
                const res = await GetNotifications()
                const data = res.data.data
                for (let i = 0; i < data.length; i++) {
                    if (data[i].is_read === false) {
                        setIsAnyUnread(true)
                        break;
                    }
                }
                setNotifications(res.data.data)
                setIsLoading(false)
            }
            catch (e) {
                error(e.response.data.message)
                setIsLoading(false)
            }
        }

        getNotifications()
    }, [navigate, location])

    return (
        <>
            <NotificationPopup notificationPopup={notificationPopup} setNotificationPopup={setNotificationPopup} notifications={notifications} setNotifications={setNotifications} setIsAnyUnread={setIsAnyUnread} isLoading={isLoading} setIsLoading={setIsLoading} />
            <LogoutPopup logoutPopup={logoutPopup} setLogoutPopup={setLogoutPopup} />

            <Navbar bg="light" expand={"xl"}>
                <Container fluid>
                    <NavLink to="/dashboard">
                        <img className='logo-img' src={Assets.DashboardLogo} alt="" />
                    </NavLink>
                    <div className='d-flex align-items-center d-xl-none'>
                        <div className='profile-wrapper'>
                            <img src={userData?.image ? userData?.image : Assets.ProfilePlaceholderImage} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} alt="" />
                        </div>
                        <div className={`bell-icon-wrapper ${isAnyUnread && 'active'}`}>
                            <FaBell className='bell-icon' onClick={() => setNotificationPopup(true)} />
                        </div>
                        <Navbar.Toggle onClick={() => setMenuOpen(true)} aria-controls="offcanvasNavbar-expand-xl" />
                    </div>
                    <Navbar.Offcanvas
                        show={menuOpen}
                        onHide={() => setMenuOpen(false)}
                        className="dashboard-offcanvas"
                        id="offcanvasNavbar-expand-xl"
                        aria-labelledby="offcanvasNavbarLabel-expand-xl"
                        placement="end"
                    >
                        <Offcanvas.Header className='justify-content-end' closeButton></Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className='d-xl-flex align-items-xl-center justify-content-xl-between w-100'>
                                <div className='mx-xl-auto d-flex flex-column flex-xl-row'>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/dashboard" className={`mb-3 mb-xl-0 ${HandleShow([roles.super_user], userRole)}`}><span className='me-3 me-xl-2'><HomeIcon /></span>Home</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/calender-events" className={`mb-3 mb-xl-0 ${HandleShow([roles.super_user, roles.supervisor, roles.employee], userRole)}`}><span className='me-3 me-xl-2'><CalendarEventIcon /></span>Calendar Events</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/shift-schedule" className={`mb-3 mb-xl-0 ${HandleShow([roles.super_user, roles.supervisor], userRole)}`}><span className='me-3 me-xl-2'><ShiftScheduleIcon /></span>Shift Schedule</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/setup/set-work-schedule" className={`mb-3 mb-xl-0 ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor], userRole)}`}><span className='me-3 me-xl-2'><SetupIcon /></span>Setup</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/competency" className={`mb-3 mb-xl-0 ${HandleShow([roles.super_user], userRole)}`}><span className="me-3 me-xl-2"><CompetencyIcon /></span>Competency</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/manage-users" className={`mb-3 mb-xl-0 ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor], userRole)}`}><span className="me-3 me-xl-2"><UserIcon /></span>Manage Users</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/manage-teams" className={`mb-3 mb-xl-0 ${HandleShow([roles.super_user], userRole)}`}><span className="me-3 me-xl-2"><UserIcon /></span>Manage Teams</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/manage-sites" className={`mb-3 mb-xl-0 ${HandleShow([roles.super_user], userRole)}`}><span className="me-3 me-xl-2"><UserIcon /></span>Manage Sites</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/settings/my-profile" className={`mb-3 mb-xl-0 d-block d-xl-none ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor, roles.employee], userRole)}`}><span className="me-3 me-xl-2"><MyProfileIcon /></span>My Profile</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/settings/change-password" className={`mb-3 mb-xl-0 d-block d-xl-none ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor, roles.employee], userRole)}`}><span className="me-3 me-xl-2"><LockIcon /></span>Change Password</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/settings/terms-and-conditions" className={`mb-3 mb-xl-0 d-block d-xl-none ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor, roles.employee], userRole)}`}><span className="me-3 me-xl-2"><TermsAndConditionsIcon /></span>Terms And Conditions</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/settings/privacy-policy" className={`mb-3 mb-xl-0 d-block d-xl-none ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor, roles.employee], userRole)}`}><span className="me-3 me-xl-2"><PrivacyIcon /></span>Privacy Policy</NavLink>
                                    <NavLink onClick={() => setMenuOpen(false)} to="/settings/faqs" className={`mb-3 mb-xl-0 d-block d-xl-none ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor, roles.employee], userRole)}`}><span className="me-3 me-xl-2"><FaqsIcon /></span>FAQs</NavLink>
                                    <NavLink onClick={() => setLogoutPopup(true)} to="#" className={`logout mb-3 mb-xl-0 d-block d-xl-none ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor, roles.employee], userRole)}`}><span className="me-3 me-xl-2"><LogoutIcon /></span>Logout</NavLink>
                                </div>
                                <div className='d-none d-xl-block'>
                                    <div className='d-flex align-items-center'>
                                        <div className={`bell-icon-wrapper ${isAnyUnread && 'active'} `}><FaBell className='bell-icon' onClick={() => setNotificationPopup(true)} /></div>
                                        <NavDropdown
                                            title={
                                                <div className="d-flex align-items-center">
                                                    <div className='profile-wrapper'>
                                                        <img src={userData?.image ? userData?.image : Assets.ProfilePlaceholderImage} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} alt="" />
                                                    </div>
                                                    <span className='ms-2'>{userData?.full_name || "-"} <span className='ms-3'><BsThreeDotsVertical /></span></span>
                                                </div>
                                            }
                                            id="offcanvasNavbarDropdown-expand-xl">
                                            <NavLink to="/settings/my-profile" className={`dropdown-item ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor, roles.employee], userRole)}`}><span><SettingIcon /></span>Settings</NavLink>
                                            <NavLink onClick={() => setLogoutPopup(true)} to="#" className={`logout dropdown-item ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor, roles.employee], userRole)}`}><span><LogoutIcon /></span>Logout</NavLink>
                                        </NavDropdown>
                                    </div>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    )
}

export default DashboardNavbar