import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form, Table } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import ScheduleTable from '../components/dashboard/ScheduleTable'
import CustomDepartmentSelect from '../components/forms/CustomDepartmentSelect'
import CustomScheduleDetailSelect from '../components/forms/CustomScheduleDetailSelect'
import CustomSiteSelect from '../components/forms/CustomSiteSelect'
import Loader from '../components/loader/Loader'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import { error } from '../constants/msg'
import { GetScheduleDetails } from '../services/schedule-details/schedule-detail'
import { shift_type } from '../utils/constants'

const ScheduleReportPage = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [scheduleDayData, setScheduleDayData] = useState(null)
    const [scheduleNightData, setScheduleNightData] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null, schedule_detail: null
    })

    const onSubmit = async () => {
        setIsDisabled(true)
        try {
            const scheduleDayData = []
            const scheduleNightData = []
            const params = {
                site_id: getValues(`${FIELD.site}`)?.value,
                department_id: getValues(`${FIELD.department}`)?.value,
                start_date: getValues(`${FIELD.schedule_detail}`)?.value.split("/")[0].trim(),
                end_date: getValues(`${FIELD.schedule_detail}`)?.value.split("/")[1].trim(),
                per_page: 14,
            }
            const res = await GetScheduleDetails(params)
            const data = res.data.data
            data?.forEach((item) => {
                if (item?.shift_type === shift_type.day) {
                    scheduleDayData.push(item)
                }
                else {
                    scheduleNightData.push(item)
                }
            })
            setScheduleDayData(scheduleDayData)
            setScheduleNightData(scheduleNightData)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data)
        }
    }

    return (
        <div className='pages schedule-report-page'>
            <Container>
                <Row className='justify-content-center'>
                    <Col xs={12} sm={10} md={10}>
                        <div className="wrapper">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row className='justify-content-center'>
                                    {/* SITE SELECT */}
                                    <Col xs={12} md={6} lg={4}>
                                        <Controller
                                            control={control}
                                            name={FIELD.site}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteSelect
                                                    {...field}
                                                    name={FIELD.site}
                                                    placeholder={"Select Site *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                                    </Col>

                                    {/* DEPARTMENT SELECT */}
                                    <Col xs={12} md={6} lg={4} className="mt-4 mt-md-0">
                                        <Controller
                                            control={control}
                                            name={FIELD.department}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomDepartmentSelect
                                                    {...field}
                                                    name={FIELD.department}
                                                    placeholder={"Select Department *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.department] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department].message}</small>}
                                    </Col>
                                </Row>

                                <Row className='mt-4'>
                                    {/* SCHEDULE DETAIL SELECT */}
                                    <Col xs={12} md={12} lg={{ offset: 2, span: 8 }}>
                                        <Controller
                                            control={control}
                                            name={FIELD.schedule_detail}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SCHEDULE_DETAIL_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomScheduleDetailSelect
                                                    {...field}
                                                    name={FIELD.schedule_detail}
                                                    placeholder={"Select Schedule Detail Dates *"}
                                                    styles=""
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.schedule_detail] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.schedule_detail].message}</small>}
                                    </Col>

                                    <Col xs={12} className="text-center mt-4">
                                        <Button type="submit" className='btn-solid'>
                                            {isDisabled ? <Loader /> : "Check"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <Row className={`justify-content-center mt-4 ${(scheduleDayData || scheduleNightData) ? "d-flex" : "d-none"}`}>
                    <Col xs={12} sm={10} md={10} className="mb-4">
                        <div className='wrapper'>
                            <div className="table-wrapper">
                                <ScheduleTable type="Day" id="day-table" data={scheduleDayData} />
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} sm={10} md={10}>
                        <div className='wrapper'>
                            <div className="table-wrapper">
                                <ScheduleTable type="Night" id="night-table" data={scheduleNightData} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ScheduleReportPage