import React, { useState, useEffect } from 'react'
import { GetSites } from '../../services/sites/sites'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import { post_type_options, recent_events_options } from '../../utils/constants'

const CustomPostTypeSelect = (props) => {
    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled}
            isLoading={false}
            isClearable={props.isClearable}
            options={post_type_options}
        />
    )
}

export default CustomPostTypeSelect