import React, { useEffect, useState, useCallback } from 'react'
import DataTable from 'react-data-table-component';
import Loader from '../loader/Loader';
import BarLoader from "react-spinners/BarLoader";
import { Button } from 'react-bootstrap';
import DeleteConfirmPopup from '../popup/DeleteConfirmPopup';

const ThemesDataTable = (props) => {
    return (
        <div className="theme-data-table">
            <div className={`${props.selectedRowsForDelete.length > 0 ? "d-block" : "d-none"}`}>
                <Button className="btn-delete" onClick={() => props.setDeleteConfirmPopup(true)}>Delete</Button>
            </div>

            <div className="wrapper">
                <DataTable
                    className='data-table'
                    columns={props.columns}
                    data={props.rows}
                    selectableRows={props.selectableRows}
                    striped
                    pagination
                    highlightOnHover
                    paginationServer
                    paginationTotalRows={props.page.totalRows}
                    paginationPerPage={props.page.perPage}
                    progressPending={props.isLoading}
                    onChangePage={(page) => props.setPage({ ...page, currentPage: page })}
                    progressComponent={
                        <BarLoader color={"#0070C0"} loading={props.isLoading} height={"4"} width={"100%"} />
                    }
                    onSelectedRowsChange={(state) => props.setSelectedRowsForDelete(state.selectedRows)}
                    clearSelectedRows={props.toggledClearRows}
                />
            </div>
        </div>
    )
}

export default ThemesDataTable