import api from "../index";
import { apiUrl, constant } from "../../utils/constants";

export const AddOtAndWorkPostData = (data) => api.post(`${constant.V1}${apiUrl.competency}/`, data)
export const GetOtAndWorkPostData = (params) => api.get(`${constant.V1}${apiUrl.competency}/`, { params })
export const GetSingleOtAndWorkPostData = (id) => api.get(`${constant.V1}${apiUrl.competency}/${id}/`)
export const EditOtAndWorkPostData = (id, data) => api.put(`${constant.V1}${apiUrl.competency}/${id}/`, data)
export const DeleteOtAndWorkPostData = (id) => api.delete(`${constant.V1}${apiUrl.competency}/${id}/`)
export const BulkDeleteOtAndWorkPostData = (ids) => api.delete(`${constant.V1}${apiUrl.competency}${apiUrl.bulk_delete}/`, { data: { ids } })

export const AddCompetencyAndErData = (data) => api.post(`${constant.V1}${apiUrl.competency_er_data}/`, data)
export const GetCompetencyAndErData = (params) => api.get(`${constant.V1}${apiUrl.competency_er_data}/`, { params })
export const GetSingleCompetencyAndErData = (id) => api.get(`${constant.V1}${apiUrl.competency_er_data}/${id}/`)
export const EditCompetencyAndErData = (id, data) => api.put(`${constant.V1}${apiUrl.competency_er_data}/${id}/`, data)
export const DeleteCompetencyAndErData = (id) => api.delete(`${constant.V1}${apiUrl.competency_er_data}/${id}/`)
export const BulkDeleteCompetencyAndErData = (ids) => api.delete(`${constant.V1}${apiUrl.competency_er_data}${apiUrl.bulk_delete}/`, { data: { ids } })

export const GetAveragePostCompetency = (params) => api.get(`${constant.V1}${apiUrl.competency}${apiUrl.avg_post_competency}/`, { params })
