import React, { useState, useEffect } from 'react'
import { GetSites } from '../../services/sites/sites'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'

const CustomSiteSelect = (props) => {
    const [options, setOptions] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [siteData, setSiteData] = useState(null)

    useEffect(() => {
        const getSites = async () => {
            const options = []
            setIsLoading(true)
            setIsDisabled(true)

            try {
                const params = { is_paginated: 0 }
                const res = await GetSites(params)
                const data = res.data.data
                data?.forEach(item => {
                    options.push({
                        label: item?.location,
                        value: item.id
                    })
                })
                if (props?.isPreFilled && !props?.getValues(`${FIELD.site}`)) {
                    props.setState({
                        ...props.state,
                        site: options[0]
                    })
                    props.setValue(`${FIELD.site}`, options[0])
                }
                setOptions(options)
                setSiteData(data)
                setIsLoading(false)
                setIsDisabled(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsDisabled(false)
                error(e.response.data.message)
            }
        }

        getSites()
    }, [])

    useEffect(() => {
        if (!props.state.site) {
            // If no site is selected than clear site,department,team,user select 
            props.setValue(`${FIELD.department}`, null)
            props.setValue(`${FIELD.team}`, null)
            props.setValue(`${FIELD.user}`, null)

            props.setValue(`${FIELD.date_a}`, null)
            props.setValue(`${FIELD.date_b}`, null)
            props.setValue(`${FIELD.date_c}`, null)
            props.setValue(`${FIELD.date_d}`, null)
            props.setValue(`${FIELD.ot_criteria}`, null)
            props.setValue(`${FIELD.slider_tolerance}`, null)
            props.setValue(`${FIELD.double_time_day}`, null)
        }
    }, [props.state.site])

    useEffect(() => {
        if (props.state.site && siteData) {
            for (let i = 0; i < siteData.length; i++) {
                if (siteData[i].id == props.state.site.value) {
                    if (siteData[i].details.schedule_type === 1) {
                        if (!Array.isArray(siteData[i].dates)) {
                            props.setValue(`${FIELD.date_a}`, new Date(siteData[i].dates.date_a))
                            props.setValue(`${FIELD.date_b}`, new Date(siteData[i].dates.date_b))
                            props.setValue(`${FIELD.date_c}`, new Date(siteData[i].dates.date_c))
                            props.setValue(`${FIELD.date_d}`, new Date(siteData[i].dates.date_d))
                        }
                    }
                    else {
                        if (!Array.isArray(siteData[i].dates)) {
                            props.setValue(`${FIELD.start_date}`, new Date(siteData[i].dates.date))
                        }
                    }

                    props.setValue(`${FIELD.ot_criteria}`, siteData[i].details.ot_criteria_display ? { label: siteData[i].details.ot_criteria_display, value: siteData[i].details.ot_criteria } : null)
                    props.setValue(`${FIELD.slider_tolerance}`, siteData[i].details.slider_tolerance ? { label: siteData[i].details.slider_tolerance, value: siteData[i].details.slider_tolerance } : null)
                    props.setValue(`${FIELD.double_time_day}`, siteData[i].details.double_time_day ? { label: siteData[i].details.double_time_day, value: siteData[i].details.double_time_day } : null)

                    break;
                }
            }
        }
    }, [props.state.site, props.getValues(`${FIELD.site}`)])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled ? true : isDisabled}
            isLoading={isLoading}
            isClearable={props.isClearable}
            options={options}
        />
    )
}

export default CustomSiteSelect