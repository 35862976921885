import React, { useState, useEffect } from 'react'
import { GetSites } from '../../services/sites/sites'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import { ot_criteria_options, post_type_options, recent_events_options } from '../../utils/constants'
import { GetDepartmentsBySite } from '../../services/departments/departments'

const CustomOtCriteriaSelect = (props) => {
    // useEffect(() => {
       
    // }, [props.state.site])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={false}
            isLoading={false}
            isClearable={props.isClearable}
            options={ot_criteria_options}
        />
    )
}

export default CustomOtCriteriaSelect