import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { FIELD, KEYS, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { success, error } from '../../constants/msg'
import { GetPosts } from '../../services/posts/posts'
import CustomSearchInput from '../forms/CustomSearchInput'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import CustomDepartmentSelect from '../forms/CustomDepartmentSelect'
import CustomUserSelect from '../forms/CustomUserSelect'
import CustomTeamSelect from '../forms/CustomTeamSelect'
import CustomInput from '../forms/CustomInput'
import CustomCompetencyUserSelect from '../forms/CustomCompetencyUserSelect'
import ClearFormPopup from './ClearFormPopup'
import { CleanAndFormatData } from '../../utils/helper'
import { ToastContainer } from 'react-toastify'
import Loader from '../loader/Loader'
import { AddCompetencyAndErData, EditCompetencyAndErData, GetSingleCompetencyAndErData } from '../../services/competency/competency'

const EditCompetencyAndErPopup = ({ editCompetencyAndErPopup, setEditCompetencyAndErPopup, selectedRow, setSelectedRow, getCompetencyAndErData }) => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const department = getValues(`${FIELD.department}`)
    const [posts, setPosts] = useState([])
    const [onEmtChecked, setOnEmtChecked] = useState(false)
    const [onErtChecked, setOnErtChecked] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null
    })

    const returnRegisteredName = (data) => {
        if (data?.post_title) {
            return data?.post_title.toLowerCase().split(" ").join("_")
        }
        else {
            return data?.title.toLowerCase().split(" ").join("_")
        }
    }

    const clearForm = () => {
        reset({
            [FIELD.site]: null,
            [FIELD.department]: null,
            [FIELD.team]: null,
            [FIELD.user]: null,
        })
        posts?.forEach((data) => {
            reset({
                [returnRegisteredName(data)]: ""
            })
        })
        setSelectedData({ role: null, site: null, department: null, team: null, user: null })
        setOnEmtChecked(false)
        setOnErtChecked(false)
        setClearFormPopup(false)
    }

    const PostInputs = () => {
        return (
            <>
                {
                    posts.map((data, i) => {
                        return (
                            <Col key={i} xs={12} md={6} className={`mb-4`}>
                                <Controller
                                    control={control}
                                    name={returnRegisteredName(data)}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: `${data?.title.toUpperCase()} is required`,
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.POST_MAX,
                                            message: VALIDATIONS_TEXT.POST_MAX,
                                        }
                                    }}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            isDisabled={false}
                                            controlId={returnRegisteredName(data)}
                                            name={returnRegisteredName(data)}
                                            placeholder={`${data.title.toUpperCase()} *`}
                                            type="number"
                                            label={`${data.title.toUpperCase()} *`}
                                            styles=""
                                        />
                                    )}
                                />
                                {errors[returnRegisteredName(data)] && <small className='text-start d-block text-danger mt-1'>{errors[returnRegisteredName(data)].message}</small>}
                            </Col>
                        )
                    })

                }

            </>
        )
    }

    const formatPostsForApi = (data) => {
        const formattedPosts = []
        posts?.forEach((item, index) => {
            formattedPosts.push({
                post: item?.id,
                value:  data['post_' + ++index]
            })
        })

        return formattedPosts
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = CleanAndFormatData(data)
        formData[FIELD.user_profile] = formData.user
        formData[FIELD.listing] = formatPostsForApi(data)
        formData[FIELD.is_emt_qualified] = onEmtChecked
        formData[FIELD.is_ert_qualified] = onErtChecked
        delete formData[FIELD.user]

        try {
            const res = await EditCompetencyAndErData(selectedRow?.id, formData)
            getCompetencyAndErData()
            success(res.data.message)
            setEditCompetencyAndErPopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        const getPostsByDepartment = async () => {
            try {
                const params = {
                    department_id: department?.value
                }
                const res = await GetPosts(params)
                setPosts(res.data.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        // call api only when department is selected
        if (department) {
            getPostsByDepartment()
        }
    }, [department])

    useEffect(() => {
        const getSingleCompetencyAndErData = async () => {
            try {
                const res = await GetSingleCompetencyAndErData(selectedRow?.id)
                const data = res.data.data
                reset({
                    ...(data.site ? { [FIELD.site]: { label: data.site_name, value: data.site } } : null),
                    ...(data.department ? { [FIELD.department]: { label: data.department_name, value: data.department } } : null),
                    ...(data.team_name ? { [FIELD.team]: { label: data.team_name, value: data.team } } : null),
                    ...(data.user_profile ? { [FIELD.user]: { label: data.full_name, value: data.user_profile } } : null),
                    ...(data.pay_type ? { [FIELD.pay_type]: { label: data.pay_type_display, value: data.pay_type } } : null),
                    [FIELD.ot_hours]: data.ot_hours
                })

                setSelectedData({
                    ...(data.site ? { site: { label: data.site_name, value: data.site } } : null),
                    ...(data.department ? { department: { label: data.department_name, value: data.department } } : null),
                    ...(data.team_name ? { team: { label: data.team_name, value: data.team } } : null),
                    ...(data.user_profile ? { user: { label: data.full_name, value: data.user_profile } } : null),
                })

                data?.listing?.forEach((data) => {
                    setValue(`${[returnRegisteredName(data)]}`, data?.value.toString())
                })

                setOnEmtChecked(data.is_emt_qualified)
                setOnErtChecked(data.is_ert_qualified)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        if (selectedRow) {
            getSingleCompetencyAndErData()
        }
    }, [selectedRow])

    useEffect(() => {
        // clear form when popup open or close
        clearForm()
        if(!editCompetencyAndErPopup){
            setSelectedRow(null)
        }
    }, [editCompetencyAndErPopup])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />
            <ToastContainer />

            <Modal
                className='theme-popup add-competencyand-er-popup'
                show={editCompetencyAndErPopup}
                onHide={() => setEditCompetencyAndErPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Edit Competency And ER Data</h1>
                                        <span className='times-icon' onClick={() => setEditCompetencyAndErPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* SITE SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.site}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteSelect
                                                    {...field}
                                                    name={FIELD.site}
                                                    placeholder={"Select Site *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    isDisabled={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                                    </Col>

                                    {/* DEPARTMENT SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.department}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomDepartmentSelect
                                                    {...field}
                                                    name={FIELD.department}
                                                    placeholder={"Select Department *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    isDisabled={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.department] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department].message}</small>}
                                    </Col>

                                    {/* TEAM SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.team}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.TEAM_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTeamSelect
                                                    {...field}
                                                    name={FIELD.team}
                                                    placeholder={"Select Team *"}
                                                    styles=""
                                                    isDisabled={true}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.team] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team].message}</small>}
                                    </Col>

                                    {/* USER SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.user}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.USER_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomCompetencyUserSelect
                                                    {...field}
                                                    name={FIELD.user}
                                                    placeholder={"Select User *"}
                                                    styles=""
                                                    callFor="competency_and_er_data"
                                                    isDependent={true}
                                                    isDisabled={true}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.user] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.user].message}</small>}
                                    </Col>

                                    {/* EMT QUALIFIED CHECKBOX */}
                                    <Col xs={12} sm={6} className={`mb-4`}>
                                        <Form.Check
                                            onChange={(e) => setOnEmtChecked(!onEmtChecked)}
                                            checked={onEmtChecked}
                                            type="checkbox"
                                            id={FIELD.is_emt_qualified}
                                            label="Emt Qualified"
                                        />
                                    </Col>

                                    {/* ERT QUALIFIED CHECKBOX */}
                                    <Col xs={12} sm={6} className={`mb-4`}>
                                        <Form.Check
                                            onChange={(e) => setOnErtChecked(!onErtChecked)}
                                            checked={onErtChecked}
                                            type="checkbox"
                                            id={FIELD.is_ert_qualified}
                                            label="Ert Qualified"
                                        />
                                    </Col>
                                </Row>

                                <Row className={`${selectedData.department ? "d-flex" : "d-none"}`}>
                                    {/* POST INPUTS */}
                                    <PostInputs />
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button type="submit" disabled={isDisabled} className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Update"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditCompetencyAndErPopup