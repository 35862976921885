import api from "../index";
import { apiUrl, constant } from "../../utils/constants";
import axios from "axios";
import { GetTokenLocalStorage } from "../localStorage/localStorage";

const config = {
    headers: {
        Authorization: 'token ' + GetTokenLocalStorage()
    }
}

export const AddUser = (data) => api.post(`${constant.V1}${apiUrl.users}/`, data)
export const GetUsers = (params) => api.get(`${constant.V1}${apiUrl.users}/`, { params })
export const GetSingleUser = (id) => api.get(`${constant.V1}${apiUrl.users}/${id}/`)
export const EditUser = (id, data) => api.put(`${constant.V1}${apiUrl.users}/${id}/`, data)
export const DeleteUser = (id) => api.delete(`${constant.V1}${apiUrl.users}/${id}/`)
export const BulkDeleteUser = (ids) => api.delete(`${constant.V1}${apiUrl.users}${apiUrl.bulk_delete}/`, { data: { ids } })
export const DownloadUserSampleCsv = () => api.get(`${constant.V1}${apiUrl.users}${apiUrl.csv_sample}/`)
export const BulkCreateUser = (data) => axios.post(`${constant.V1}${apiUrl.users}${apiUrl.bulk_create}/`, data, {
    headers: {
        Authorization: 'token ' + GetTokenLocalStorage()
    }
})

