import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomInput from '../forms/CustomInput'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { BsFillBellFill } from "react-icons/bs"
import { GetNotifications, MarkAllNotificationAsRead, MarkNotificationAsRead } from '../../services/notifications/notifications'
import { error } from '../../constants/msg'
import { GetFormattedDate } from '../../utils/helper'
import Loader from '../loader/Loader'

const NotificationPopup = ({ notificationPopup, setNotificationPopup, notifications, setNotifications, setIsAnyUnread, isLoading, setIsLoading }) => {
    const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const markNotificationAsRead = async (id, index) => {
        try {
            let temp = [...notifications]
            temp[index].is_read = true
            setNotifications(temp)

            await MarkNotificationAsRead(id)
        }
        catch (e) {
            error(e.reponse.data.message)
        }
    }

    const markAllNotificationAsRead = async () => {
        setIsLoading(true)
        try {
            const res = await MarkAllNotificationAsRead()
            setNotifications(res.data.data)
            setIsAnyUnread(false)
            setIsLoading(false)
        }
        catch (e) {
            error(e.reponse.data.message)
            setIsLoading(false)
        }
    }

    return (
        <>
            <Modal
                className='theme-popup notification-popup'
                show={notificationPopup}
                onHide={() => setNotificationPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Notifications</h1>
                                        <span className='times-icon' onClick={() => setNotificationPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='mt-4'>
                            <Row>
                                <Col xs={12}>
                                    <div className="notification-wrapper">
                                        <div className="wrapper">
                                            <div className='text-end mb-2'>
                                                <small className='mark-read' onClick={markAllNotificationAsRead}>Mark all as read</small>
                                            </div>

                                            <ul>
                                                {
                                                    isLoading ?
                                                        <li className='text-center'><Loader /></li>
                                                        :
                                                        notifications.length > 0 ?
                                                            notifications?.map((data, index) => (
                                                                <li key={index} onClick={() => markNotificationAsRead(data?.id, index)}>
                                                                    <div className={`box-wrapper d-flex align-items-center ${!data.is_read && "notification-unread"}`}>
                                                                        <div className='icon-wrapper'>
                                                                            <BsFillBellFill />
                                                                        </div>

                                                                        <div className='ms-3'>
                                                                            <p>{data?.body}</p>
                                                                            <small><b>{GetFormattedDate(data?.created_at)}</b></small>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                            :
                                                            <li>
                                                                <h2 className='text-center'>No notifications yet</h2>
                                                            </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NotificationPopup