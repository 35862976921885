import React from 'react'
import { Container } from 'react-bootstrap'
import { Row, Col } from "react-bootstrap"
import Assets from '../../constants/images'

const AuthLayout = ({ children }) => {
    return (
        <section className='auth-layout'>
            <div className='d-flex h-100'>
                <div className='left-wrapper'>
                    <div className='bg-wrapper d-none d-md-block'></div>
                    <div className="wrapper-container">
                        {children}
                    </div>
                </div>

                <div className='right-wrapper'>
                    <div className='w-100 h-100 d-flex align-items-center'>
                        <div className=''>
                            <div className='logo-wrapper'>
                                <img src={Assets.Logo} alt="" />
                            </div>

                            <div className='content-wrapper mt-4'>
                                <h1>Welcome back <br /> to WTT</h1>
                                <p className='mt-20'>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Cras eget fermentum diam,
                                    pulvinar dignissim mauris. Donec eget leo
                                    sit amet dui cursus faucibus.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AuthLayout