import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import CustomInput from '../forms/CustomInput'
import { FIELD, KEYS, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import CustomDepartmentSelect from '../forms/CustomDepartmentSelect'
import CustomRoleSelect from '../forms/CustomRoleSelect'
import CustomTeamSelect from '../forms/CustomTeamSelect'
import { CleanAndFormatData, HandleRegister, HandleShow } from '../../utils/helper'
import { roles } from '../../utils/constants'
import CustomUserSelect from '../forms/CustomUserSelect'
import ClearFormPopup from "../popup/ClearFormPopup"
import { ToastContainer } from 'react-toastify'
import { AddUsers, EditUser, GetSingleUser, GetUsers } from "../../services/users/users"
import { success, error } from '../../constants/msg'
import Loader from '../loader/Loader'
import { EditTeam, GetSingleTeam } from '../../services/teams/teams'

const EditTeamPopup = ({ editTeamPopup, setEditTeamPopup, selectedData, setSelectedData, selectedRow, getTeams }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [team, setTeam] = useState(null)

    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        setIsDisabled(true)
        delete data.team
        const formData = CleanAndFormatData(data)
        try {
            const res = await EditTeam(selectedRow.id, formData)
            getTeams()
            success(res.data.message)
            setEditTeamPopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    const clearForm = () => {
        reset({
            [FIELD.user_profiles]: null,
        })
        setSelectedData({ ...selectedData, role: null, site: null, department: null, user: null })
        setClearFormPopup(false)
    }

    useEffect(() => {
        // clear form when popup open or close
        clearForm()
    }, [editTeamPopup])

    useEffect(() => {
        const getSingleTeam = async () => {
            const user_profiles = []
            try {
                const res = await GetSingleTeam(selectedRow?.id)
                const data = res.data.data
                data?.members?.forEach((item) => {
                    user_profiles.push({
                        label: item?.full_name,
                        value: item?.user_profile
                    })
                })
                setTeam(data)
                reset({
                    [FIELD.team]: data.name,
                    [FIELD.user_profiles]: user_profiles,
                    [FIELD.site]: { label: data?.site_name, value: data?.site }
                })
                setSelectedData({
                    ...selectedData,
                    site: { label: data?.site_name, value: data?.site },
                    team: { label: data?.name, value: data?.id }
                })
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        if (selectedRow) {
            getSingleTeam()
        }
    }, [selectedRow])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />
            <ToastContainer />

            <Modal
                className='theme-popup edit-team-popup'
                show={editTeamPopup}
                onHide={() => setEditTeamPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Edit Team</h1>
                                        <span className='times-icon' onClick={() => setEditTeamPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* TEAM INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.team}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.TEAM_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={true}
                                                    controlId={FIELD.team}
                                                    name={FIELD.team}
                                                    placeholder="Team Name *"
                                                    type="text"
                                                    label="Team Name *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.team] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team].message}</small>}
                                    </Col>

                                    {/* USER SELECT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.user_profiles}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.USER_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomUserSelect
                                                    {...field}
                                                    name={FIELD.user_profiles}
                                                    placeholder={"Select User *"}
                                                    styles=""
                                                    isMulti={true}
                                                    isDependent={false}
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    isEditable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.user_profiles] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.user_profiles].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button disabled={isDisabled} type="submit" className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Update"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditTeamPopup