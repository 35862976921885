import React, { useState, useEffect } from 'react'
import { GetTeams } from '../../services/teams/teams'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import { teams_options } from '../../utils/constants'

const CustomTeamSelect = (props) => {
    const [options, setOptions] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        // Fetch teams
        const getTeams = async () => {
            setIsLoading(true)
            setTimeout(() => {
                setOptions(teams_options)
                setIsLoading(false)
                setIsDisabled(false)
            }, 200)
        }

        if (props.state.department) {
            // Fetch teams only when department is selected
            getTeams()
        }
    }, [props.state.department])

    useEffect(() => {
        if (!props.state.team) {
            // If no team is selected than clear user select 
            props.setValue(`${FIELD.user}`, null)
        }

        if (!props.getValues(`${FIELD.department}`) || !props.getValues(`${FIELD.site}`)) {
            // Disabled the teams if there is no department
            setIsDisabled(true)
        }
    }, [props.state])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled ? true : isDisabled}
            isLoading={isLoading}
            isClearable={props.isClearable}
            options={options}
        />
    )
}

export default CustomTeamSelect