import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import { ToastContainer } from 'react-toastify'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import { BsCloudArrowDownFill, BsCloudArrowUpFill } from 'react-icons/bs'
import { BulkCreateUser, BulkDeleteUser, DeleteUser, DownloadUserSampleCsv, GetUsers } from '../services/users/users'
import { error, success } from '../constants/msg'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import { GetBackgroundColor, HandleRegister } from '../utils/helper'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import CustomSiteSelect from '../components/forms/CustomSiteSelect'
import { useForm, Controller } from 'react-hook-form'
import CustomRoleSelect from '../components/forms/CustomRoleSelect'
import EditUserPopup from '../components/popup/EditUserPopup'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import CustomSearchInput from '../components/forms/CustomSearchInput'
import UploadCsvPopup from '../components/popup/UploadCsvPopup'
import { saveAs } from "file-saver"
import CustomDepartmentSelect from '../components/forms/CustomDepartmentSelect'
import AddOtWorkPopup from '../components/popup/AddOtWorkPostPopup'
import CustomCompetencySelect from '../components/forms/CustomCompetencySelect'
import { competency_select_options } from '../utils/constants'
import { BulkDeleteCompetencyAndErData, BulkDeleteOtAndWorkPostData, DeleteCompetencyAndErData, DeleteOtAndWorkPostData, GetAveragePostCompetency, GetCompetencyAndErData, GetOtAndWorkPost, GetOtAndWorkPostData } from '../services/competency/competency'
import AddCompetencyAndErPopup from '../components/popup/AddCompetencyAndErPopup'
import AddOtWorkPostPopup from '../components/popup/AddOtWorkPostPopup'
import EditOtWorkPostPopup from '../components/popup/EditOtWorkPostPopup'
import EditCompetencyAndErPopup from '../components/popup/EditCompetencyAndErPopup'
import Loader from '../components/loader/Loader'
import ThemeChart from '../components/dashboard/ThemeChart'
import { NavLink } from 'react-router-dom'

const CompetencyPage = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [averagePostCompetency, setAveragePostCompetency] = useState([])
    const [isChartLoading, setIsChartLoading] = useState(true)
    const [labels, setLabels] = useState([])
    const [datasets, setDatasets] = useState([])
    const site = watch(FIELD.site)
    const department = watch(FIELD.department)
    const competency = watch(FIELD.competency)
    const [addOtWorkPostPopup, setAddOtWorkPostPopup] = useState(false)
    const [addCompetencyAndErPopup, setAddCompetencyAndErPopup] = useState(false)
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [editOtWorkPostPopup, setEditOtWorkPostPopup] = useState(false)
    const [editCompetencyAndErPopup, setEditCompetencyAndErPopup] = useState(false)
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null, competency: null, site_chart: null, department_chart: null
    })
    const otAndWorkPostColumns = [
        {
            name: 'Team ',
            selector: rowData => rowData.team,
            sortable: true,
        },
        {
            name: 'Name',
            selector: rowData => rowData.name,
            sortable: true,
        },
        {
            name: 'Salary Type',
            selector: rowData => rowData.salary_type,
            sortable: true,
        },
        {
            name: 'OT Hours',
            selector: rowData => rowData.ot_hours,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (rowData, row) => <ThemeDataTableAction link="" row={row} rowData={rowData} setEditPopup={setEditOtWorkPostPopup} setSelectedRow={setSelectedRow} viewAction={false} />
        },
    ]

    const competencyAndErDataColumns = [
        {
            name: 'Team ',
            selector: rowData => rowData.team,
            sortable: true,
        },
        {
            name: 'Name',
            selector: rowData => rowData.name,
            sortable: true,
        },
        {
            name: 'EMT Qualified',
            selector: rowData => rowData.is_emt_qualified,
            cell: (rowData, row) => <Form.Check disabled type="checkbox" checked={rowData?.is_emt_qualified} />
        },
        {
            name: 'ERT Qualified Hours',
            selector: rowData => rowData.is_ert_qualified,
            cell: (rowData, row) => <Form.Check disabled type="checkbox" checked={rowData?.is_ert_qualified} />
        },
        {
            name: 'Action',
            cell: (rowData, row) => <ThemeDataTableAction link="" row={row} rowData={rowData} setEditPopup={setEditCompetencyAndErPopup} setSelectedRow={setSelectedRow} viewAction={false} />
        },
    ]

    const generateRowsForOtAndWorkPostData = (rows, data) => {
        data?.data?.forEach((item, index) => {
            rows.push({
                id: item?.id.toString() || "-",
                team: item?.team_name || "-",
                name: item?.full_name || "-",
                salary_type: item?.pay_type_display || "-",
                ot_hours: item?.ot_hours.toString() || "-",
            })
            // generating rows dynamimcally A/C to POST
            item?.listing?.forEach((listing) => {
                let key = listing?.post_title.toLowerCase().split(" ").join("_")
                rows[index][key] = listing?.value.toString() || "-"
            })
        })
        setRows(rows)
    }

    const generateColumnsForOtAndWorkPostData = (columns, data) => {
        // generating columns dynamimcally A/C to POST
        data?.data[0]?.listing?.forEach((listing) => {
            let key = listing?.post_title.toLowerCase().split(" ").join("_")
            columns.push({
                name: listing?.post_title || "-",
                selector: rowData => rowData[key],
                sortable: true,
            })
        })
        // append dynamics column at index 3 to move action tab to last
        otAndWorkPostColumns.splice(3, 0, ...columns)
        setColumns(otAndWorkPostColumns)
    }

    const generateRowsForCompetencyAndErData = (rows, data) => {
        data?.data?.forEach((item, index) => {
            rows.push({
                id: item?.id.toString() || "-",
                team: item?.team_name || "-",
                name: item?.full_name || "-",
                is_emt_qualified: item?.is_emt_qualified,
                is_ert_qualified: item?.is_ert_qualified,
            })
            // generating rows dynamimcally A/C to POST
            item?.listing?.forEach((listing) => {
                let key = listing?.post_title.toLowerCase().split(" ").join("_")
                rows[index][key] = listing?.value.toString() || "-"
            })
        })
        setRows(rows)
    }

    const generateColumnsForCompetencyAndErData = (columns, data) => {
        // generating columns dynamimcally A/C to POST
        data?.data[0]?.listing?.forEach((listing) => {
            let key = listing?.post_title.toLowerCase().split(" ").join("_")
            columns.push({
                name: listing?.post_title || "-",
                selector: rowData => rowData[key],
                sortable: true,
            })
        })
        // append dynamics column at index 3 to move action tab to last
        competencyAndErDataColumns.splice(4, 0, ...columns)
        setColumns(competencyAndErDataColumns)
    }

    const clearForm = () => {

    }

    const getOtAndWorkPostData = async () => {
        setIsLoading(true)
        const rows = []
        const columns = []
        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
                ...(search && { search }),
                department_id: department?.value
            }
            const res = await GetOtAndWorkPostData(params)
            const data = res.data
            generateRowsForOtAndWorkPostData(rows, data)
            generateColumnsForOtAndWorkPostData(columns, data)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const getCompetencyAndErData = async () => {
        setIsLoading(true)
        const rows = []
        const columns = []

        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
                ...(search && { search }),
                department_id: department?.value
            }
            const res = await GetCompetencyAndErData(params)
            const data = res.data
            generateRowsForCompetencyAndErData(rows, data)
            generateColumnsForCompetencyAndErData(columns, data)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e.response.data.message)
        }
    }

    const deleteOtAndWorkPostData = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteOtAndWorkPostData(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteOtAndWorkPostData(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getOtAndWorkPostData()
        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e.response.data.message)
        }
    }

    const deleteCompetencyAndErData = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteCompetencyAndErData(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteCompetencyAndErData(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getCompetencyAndErData()
        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e.response.data.message)
        }
    }

    const generateLabelsAndDatasets = (data) => {
        let labels = []
        let datasets = []
        let teams = []

        data[0].competency?.map((team) => {
            /*
                Generating schema for teams like 
                teams = [
                    { 
                        team_a : Team A,
                        data: [],
                        display_name: "Team A"
                    }
                ]
            */

            const key = "team_" + team?.team_name.toLowerCase().split(" ").join("_")
            teams.push({
                [key]: "Team " + team?.team_name,
                data: [],
                display_name: team?.team_name
            })
        })

        data?.forEach((item) => {
            labels.push(item?.post_name)
            /*
                For each dates push team data in their dataset
                teams = [
                    { 
                        team_a : Team A,
                        data: [1,2,5,6,7],
                        display_name: "Team A"
                    },
                    
                ]
            */
            item?.competency?.forEach((team, i) => {
                teams[i].data.push(team?.avg_competency)
            })
        })

        teams?.forEach((team, index) => {
            /*
               Create dataset for each team
               teams = [
                   { 
                        label : Team A,
                        data: [1,2,5,6,7],
                        backgroundColor: "#45S2Ad"
                   }
               ]
           */
            datasets.push({
                label: team?.display_name,
                data: [...team?.data],
                backgroundColor: GetBackgroundColor(index),
            })
        })

        setLabels(labels)
        setDatasets(datasets)
    }

    const AddEditComponent = () => {
        return (
            <>
                {
                    competency?.value === 1 || !competency ?
                        <>
                            <AddOtWorkPostPopup addOtWorkPostPopup={addOtWorkPostPopup} setAddOtWorkPostPopup={setAddOtWorkPostPopup} getOtAndWorkPostData={getOtAndWorkPostData} />
                            <EditOtWorkPostPopup editOtWorkPostPopup={editOtWorkPostPopup} setEditOtWorkPostPopup={setEditOtWorkPostPopup} selectedRow={selectedRow} setSelectedRow={setSelectedRow} getOtAndWorkPostData={getOtAndWorkPostData} />
                        </>
                        :
                        <>
                            <AddCompetencyAndErPopup addCompetencyAndErPopup={addCompetencyAndErPopup} setAddCompetencyAndErPopup={setAddCompetencyAndErPopup} rows={rows} setRows={setRows} getCompetencyAndErData={getCompetencyAndErData} />
                            <EditCompetencyAndErPopup editCompetencyAndErPopup={editCompetencyAndErPopup} setEditCompetencyAndErPopup={setEditCompetencyAndErPopup} selectedRow={selectedRow} setSelectedRow={setSelectedRow} getCompetencyAndErData={getCompetencyAndErData} />
                        </>
                }
            </>
        )
    }

    useEffect(() => {
        /* 
            Call api only when all filters are selected  
            Also check if 'OT & Work post' is selected than 
            call 'OTAndWorkPost Api' else call other one
        */
        if (site?.value && department?.value && competency?.value) {
            if (competency?.value === 1) {
                getOtAndWorkPostData()
            }
            else {
                getCompetencyAndErData()
            }
        }
    }, [page.currentPage, search, site, department, competency])

    useEffect(() => {
        clearForm()
    }, [addOtWorkPostPopup])

    useEffect(() => {
        const getAveragePostCompetency = async () => {
            setIsChartLoading(true)
            try {
                const params = {
                    site_id: site?.value,
                    department_id: department?.value
                }
                const res = await GetAveragePostCompetency(params)
                const data = res.data.data
                generateLabelsAndDatasets(data)
                setAveragePostCompetency(data)
                setIsChartLoading(false)
            }
            catch (e) {
                setIsChartLoading(false)
                error(e.response.data.message)
            }
        }

        if (site && department) {
            getAveragePostCompetency()
        }
    }, [site, department])

    useEffect(() => {
        setValue(`${[FIELD.competency]}`, competency_select_options[0])
    }, [])

    return (
        <>
            <AddEditComponent />
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={competency?.value === 1 ? deleteOtAndWorkPostData : deleteCompetencyAndErData} />
            <ToastContainer />

            <div className='pages competency-page'>
                <Container>
                    <Row>
                        <Col xs={12} sm={8} className="pt-2">
                            <h1>Calendar Events</h1>
                            <h1 className='mt-3'>OT & Work Post Data</h1>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} sm={6} xl={4} className="mb-4">
                            <CustomSearchInput
                                search={search}
                                onChange={(e) => setSearch(e.target.value)}
                                label="Search"
                                placeholder="Search"
                            />
                        </Col>

                        <Col xs={12} xl={8} className="mb-4">
                            <Row className="justify-content-end">
                                {/* SITE FILTER SELECT */}
                                <Col xs={12} sm={6} md={6} lg={3} xl={3} className="mb-4">
                                    <Controller
                                        control={control}
                                        name={FIELD.site}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomSiteSelect
                                                {...field}
                                                name={FIELD.site}
                                                placeholder={"Select Site"}
                                                styles="small-select"
                                                isClearable={false}
                                                isPreFilled={true}
                                                state={selectedData}
                                                setState={setSelectedData}
                                                setValue={setValue}
                                                getValues={getValues}
                                            />
                                        )}
                                    />
                                </Col>

                                {/* DEPARTMENT FILTER SELECT */}
                                <Col xs={12} sm={6} md={6} lg={3} xl={3} className="mb-4">
                                    <Controller
                                        control={control}
                                        name={FIELD.department}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomDepartmentSelect
                                                {...field}
                                                name={FIELD.department}
                                                placeholder={"Select Department"}
                                                styles="small-select"
                                                isClearable={false}
                                                isPreFilled={true}
                                                state={selectedData}
                                                setState={setSelectedData}
                                                setValue={setValue}
                                                getValues={getValues}
                                            />
                                        )}
                                    />
                                </Col>

                                {/* COMPETENCY FILTER SELECT */}
                                <Col xs={12} sm={6} md={6} lg={3} xl={3} className="mb-4">
                                    <Controller
                                        control={control}
                                        name={FIELD.competency}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: VALIDATIONS_TEXT.COMPETENCY_REQUIRED,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomCompetencySelect
                                                {...field}
                                                name={FIELD.competency}
                                                placeholder={"Select Competency"}
                                                styles="small-select"
                                                isClearable={false}
                                                isPreFilled={true}
                                                state={selectedData}
                                                setState={setSelectedData}
                                                setValue={setValue}
                                                getValues={getValues}
                                            />
                                        )}
                                    />
                                </Col>

                                <Col xs={12} sm={6} md={6} lg={3} xl={3} className="mb-4">
                                    <Button
                                        className='w-100 btn-solid'
                                        onClick={() => {
                                            if (competency?.value === 1) {
                                                setAddOtWorkPostPopup(true)
                                            }
                                            else {
                                                setAddCompetencyAndErPopup(true)
                                            }
                                        }}>
                                        + Add Competency
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {
                        site && !department ?
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-center flex-column mt-5'>
                                        <h1 className='mt-5'>Please Select Site And Department</h1>
                                    </div>
                                </Col>
                            </Row>
                            :
                            <>
                                <Row>
                                    <Col xs={12} className="mb-4">
                                        <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={true} toggledClearRows={toggledClearRows} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        <div className="chart-wrapper">
                                            <Row>
                                                <Col xl={12} className="pt-2 mb-4">
                                                    <h1>Competency</h1>
                                                </Col>

                                                <Col xs={12}>
                                                    {
                                                        isChartLoading ?
                                                            <div className="text-center"><Loader /></div>
                                                            :
                                                            averagePostCompetency?.length > 0 ?
                                                                <>
                                                                    <div className='text-center mb-4'>
                                                                        <h2>Average Post Competency</h2>
                                                                    </div>

                                                                    <ThemeChart labels={labels} datasets={datasets} />
                                                                </>
                                                                :
                                                                <div className="text-center"><h1>No Data Available</h1></div>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                    }
                </Container>
            </div>
        </>
    )
}

export default CompetencyPage