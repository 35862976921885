import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import CustomInput from '../forms/CustomInput'
import { FIELD, KEYS, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import CustomDepartmentSelect from '../forms/CustomDepartmentSelect'
import CustomRoleSelect from '../forms/CustomRoleSelect'
import CustomTeamSelect from '../forms/CustomTeamSelect'
import { CleanAndFormatData, HandleRegister, HandleShow } from '../../utils/helper'
import { pay_type_options, roles } from '../../utils/constants'
import CustomUserSelect from '../forms/CustomUserSelect'
import ClearFormPopup from "../popup/ClearFormPopup"
import { ToastContainer } from 'react-toastify'
import { AddUser, GetUsers } from "../../services/users/users"
import { success, error } from '../../constants/msg'
import Loader from '../loader/Loader'
import CustomSelect from '../forms/CustomSelect'
import { GetPosts } from '../../services/posts/posts'
import CustomCompetencyUserSelect from '../forms/CustomCompetencyUserSelect'
import { AddOtAndWorkPostData, EditOtAndWorkPostData, GetSingleOtAndWorkPostData } from '../../services/competency/competency'

const EditOtWorkPostPopup = ({ editOtWorkPostPopup, setEditOtWorkPostPopup, selectedRow, setSelectedRow, getOtAndWorkPostData }) => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const department = getValues(`${FIELD.department}`)
    const [posts, setPosts] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null
    })

    const returnRegisteredName = (data, index) => {
        if (data?.post_title) {
            return data?.post_title.toLowerCase().split(" ").join("_")
        }
        else {
            return data?.title.toLowerCase().split(" ").join("_")
        }
    }

    const clearForm = () => {
        reset({
            [FIELD.site]: null,
            [FIELD.department]: null,
            [FIELD.team]: null,
            [FIELD.user]: null,
            [FIELD.pay_type]: null,
            [FIELD.ot_hours]: "",
        })
        posts?.forEach((data) => {
            setValue(`${[returnRegisteredName(data)]}`, '')
        })
        setSelectedData({ role: null, site: null, department: null, team: null, user: null })
        setClearFormPopup(false)
    }

    const PostInputs = () => {
        return (
            <>
                {
                    posts.map((data, i) => {
                        return (
                            <Col key={i} xs={12} md={6} className={`mb-4`}>
                                <Controller
                                    control={control}
                                    name={returnRegisteredName(data, i)}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: `${data?.title.toUpperCase()} is required`,
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.POST_MAX,
                                            message: VALIDATIONS_TEXT.POST_MAX,
                                        }
                                    }}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            isDisabled={false}
                                            controlId={returnRegisteredName(data)}
                                            name={returnRegisteredName(data)}
                                            placeholder={`${data.title.toUpperCase()} *`}
                                            type="number"
                                            label={`${data.title.toUpperCase()} *`}
                                            styles=""
                                        />
                                    )}
                                />
                                {errors[returnRegisteredName(data)] && <small className='text-start d-block text-danger mt-1'>{errors[returnRegisteredName(data)].message}</small>}
                            </Col>
                        )
                    })

                }

            </>
        )
    }

    const formatPostsForApi = (data) => {
        const formattedPosts = []
        posts?.forEach((item, index) => {
            formattedPosts.push({
                post: item?.id,
                value: data['post_' + ++index]
            })
        })

        return formattedPosts
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = CleanAndFormatData(data)
        formData[FIELD.user_profile] = formData.user
        formData[FIELD.listing] = formatPostsForApi(data)
        delete formData[FIELD.user]

        try {
            const res = await EditOtAndWorkPostData(selectedRow?.id, formData)
            getOtAndWorkPostData()
            success(res.data.message)
            setEditOtWorkPostPopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e.response.data.message)
        }
    }

    useEffect(() => {
        if (!getValues(`${FIELD.user}`)) {
            setValue(`${[FIELD.pay_type]}`, null)
            setValue(`${[FIELD.ot_hours]}`, "")
        }
    }, [selectedData])

    useEffect(() => {
        const getPostsByDepartment = async () => {
            try {
                const params = {
                    department_id: department?.value
                }
                const res = await GetPosts(params)
                setPosts(res.data.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        // call api only when department is selected
        if (department) {
            getPostsByDepartment()
        }
    }, [department])

    useEffect(() => {
        const getSingleOtAndWorkPostData = async () => {
            try {
                const res = await GetSingleOtAndWorkPostData(selectedRow?.id)
                const data = res.data.data
                reset({
                    ...(data.site ? { [FIELD.site]: { label: data.site_name, value: data.site } } : null),
                    ...(data.department ? { [FIELD.department]: { label: data.department_name, value: data.department } } : null),
                    ...(data.team_name ? { [FIELD.team]: { label: data.team_name, value: data.team } } : null),
                    ...(data.user_profile ? { [FIELD.user]: { label: data.full_name, value: data.user_profile } } : null),
                    ...(data.pay_type ? { [FIELD.pay_type]: { label: data.pay_type_display, value: data.pay_type } } : null),
                    [FIELD.ot_hours]: data.ot_hours
                })

                setSelectedData({
                    ...(data.site ? { site: { label: data.site_name, value: data.site } } : null),
                    ...(data.department ? { department: { label: data.department_name, value: data.department } } : null),
                    ...(data.team_name ? { team: { label: data.team_name, value: data.team } } : null),
                    ...(data.user_profile ? { user: { label: data.full_name, value: data.user_profile } } : null),
                })

                data?.listing?.forEach((data) => {
                    setValue(`${[returnRegisteredName(data)]}`, data?.value.toString())
                })
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        if (selectedRow) {
            getSingleOtAndWorkPostData()
        }
    }, [selectedRow])

    useEffect(() => {
        // clear form when popup open or close
        clearForm()
        if (!editOtWorkPostPopup) {
            setSelectedRow(null)
        }
    }, [editOtWorkPostPopup])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />
            <ToastContainer />

            <Modal
                className='theme-popup add-ot-work-popup'
                show={editOtWorkPostPopup}
                onHide={() => setEditOtWorkPostPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Edit OT & Work Post Data</h1>
                                        <span className='times-icon' onClick={() => setEditOtWorkPostPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* SITE SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.site}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteSelect
                                                    {...field}
                                                    name={FIELD.site}
                                                    placeholder={"Select Site *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    isDisabled={true}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                                    </Col>

                                    {/* DEPARTMENT SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.department}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomDepartmentSelect
                                                    {...field}
                                                    name={FIELD.department}
                                                    placeholder={"Select Department *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    isDisabled={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.department] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department].message}</small>}
                                    </Col>

                                    {/* TEAM SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.team}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.TEAM_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTeamSelect
                                                    {...field}
                                                    name={FIELD.team}
                                                    placeholder={"Select Team *"}
                                                    styles=""
                                                    isDisabled={true}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.team] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team].message}</small>}
                                    </Col>

                                    {/* USER SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.user}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.USER_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomCompetencyUserSelect
                                                    {...field}
                                                    name={FIELD.user}
                                                    placeholder={"Select User *"}
                                                    styles=""
                                                    callFor="ot_and_work_post_data"
                                                    isDependent={true}
                                                    isDisabled={true}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.user] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.user].message}</small>}
                                    </Col>

                                    {/* PAY TYPE SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.pay_type}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.PAY_TYPE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSelect
                                                    {...field}
                                                    placeholder={"Select Pay Type *"}
                                                    isDisabled={!getValues(`${FIELD.user}`) ? true : false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    options={pay_type_options}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.pay_type] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.pay_type].message}</small>}
                                    </Col>

                                    {/* OT HOURS INPUT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.ot_hours}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.OT_HOURS_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={!getValues(`${FIELD.user}`) ? true : false}
                                                    controlId={FIELD.ot_hours}
                                                    name={FIELD.ot_hours}
                                                    placeholder="OT Hours *"
                                                    type="number"
                                                    label="OT Hours *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.ot_hours] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.ot_hours].message}</small>}
                                    </Col>
                                </Row>

                                <Row className={`${selectedData.department ? "d-flex" : "d-none"}`}>
                                    {/* POST INPUTS */}
                                    <PostInputs />
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button disabled={isDisabled} type="submit" className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Update"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditOtWorkPostPopup