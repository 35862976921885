import React, { useRef, useEffect, useState } from 'react'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { BsCloudArrowDownFill } from 'react-icons/bs'
import { days_array } from '../../utils/constants'
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Loader from '../loader/Loader';

const ScheduleTable = (props) => {
    const [posts, setPosts] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    const handlePosts = (data) => {
        const posts = {}

        data?.forEach((item) => {
            item?.team_members?.forEach((member) => {
                const key = member.post_name.toLowerCase().split(" ").join("_")
                if (!posts.hasOwnProperty(key)) {
                    posts[key] = []
                }
                posts[key].push({
                    post_name: member?.post_name,
                    member_name: member?.full_name,
                    competency: member?.competency
                })
            })
        })
        setPosts(posts)
    }

    const downloadPdf = async () => {
        setIsDisabled(true);
        try {
            const tableToDownload = document.getElementById(props.id);
            const canvas = await html2canvas(tableToDownload)
            const img = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
                orientation: "landscape",
                unit: "in",
                format: [30, 30],
            });
            pdf.addImage(img, "JPEG", 0, 0);
            pdf.save("schedule.pdf");
            setIsDisabled(false);
        }
        catch (e) {
            setIsDisabled(false);
        }
    }

    useEffect(() => {
        handlePosts(props?.data)
    }, [props])

    return (
        <div className='schedule-table'>
            <div className="text-end mb-3">
                {
                    isDisabled ?
                        <div className="text-end"><Loader /></div>
                        :
                        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Download Pdf</Tooltip>}>
                            <span className='download-span ms-4' onClick={downloadPdf}><BsCloudArrowDownFill className="icon" /> Download Pdf</span>
                        </OverlayTrigger>
                }
            </div>

            <div id={props.id}>
                <Table responsive className='text-center'>
                    <tr className='days-row'>
                        <th></th>
                        {
                            days_array?.map((day, index) => (
                                <th key={`day-${index}`} colSpan={2}>{day}</th>
                            ))
                        }
                    </tr>

                    <tr className='dates-row'>
                        <th></th>
                        {
                            props?.data?.map((item, index) => (
                                <th key={`date-${index}`} colSpan={2}>{item?.date}</th>
                            ))
                        }
                    </tr>

                    <tr className="team-competency-row">
                        <th className='day-th'>{props?.type}</th>
                        {
                            props?.data?.map((item, index) => (
                                <>
                                    <th>{item?.team_name}</th>
                                    <th className='competency-th'>Comp</th>
                                </>
                            ))
                        }
                    </tr>

                    {/* Posts And Competency Row */}
                    {
                        posts &&
                        Object.keys(posts).map((key) => (
                            <tr className='posts-row'>
                                <th>{key?.toUpperCase().split("_").join(" ")}</th>
                                {
                                    posts[key].map((data, index) => (
                                        <>
                                            <td className='member-td'>{data?.member_name}</td>
                                            <td>{data?.competency}</td>
                                        </>
                                    ))
                                }
                            </tr>
                        ))
                    }

                    <tr className='fireteam-row'>
                        <th className='fireteam-th'>Fire Team</th>
                        {
                            props.data?.map((data, index) => (
                                <td colSpan="2">
                                    {
                                        data?.team_members?.map((member, i) => {
                                            if (member?.is_ert) {
                                                return (
                                                    <td className='member-name-td'>{member?.full_name}</td>
                                                )
                                            }
                                        })
                                    }
                                </td>
                            ))
                        }
                    </tr>

                    <tr className='emt-row'>
                        <th className='emt-th'>EMT Team</th>
                        {
                            props.data?.map((data, index) => (
                                <td colSpan="2">
                                    {
                                        data?.team_members?.map((member, i) => {
                                            if (member?.is_emt) {
                                                return (
                                                    <td className='member-name-td'>{member?.full_name}</td>
                                                )
                                            }
                                        })
                                    }
                                </td>
                            ))
                        }
                    </tr>

                    <tr className='extra-board-row'>
                        <th className='extra-board-th'>Extra Board</th>
                        {
                            props.data?.map((data, index) => (
                                <td colSpan="2">
                                    {
                                        data?.extra_board?.map((board, i) => {
                                            return (
                                                <td className='member-name-td'>{board?.full_name}</td>
                                            )
                                        })
                                    }
                                </td>
                            ))
                        }
                    </tr>

                    <tr className='vacation-row'>
                        <th className='vacation-th'>Vacation/Off</th>
                        {
                            props.data?.map((data, index) => (
                                <td colSpan="2">
                                    {
                                        data?.vacations?.map((vacation, i) => {
                                            return (
                                                <td className='member-name-td'>{vacation?.full_name}</td>
                                            )
                                        })
                                    }
                                </td>
                            ))
                        }
                    </tr>

                    <tr className='train-assign-row'>
                        <th className='train-assign-th'>Train/Assign</th>
                        {
                            props.data?.map((data, index) => (
                                <td colSpan="2">
                                    {
                                        data?.train_assign?.map((trainAssign, i) => {
                                            return (
                                                <td className='member-name-td'>{trainAssign?.full_name}</td>
                                            )
                                        })
                                    }
                                </td>
                            ))
                        }
                    </tr>

                    <tr className='slide-to-night-row'>
                        <th className='slide-to-night-th'>Slide to Nights</th>
                        {
                            props.data?.map((data, index) => (
                                <td colSpan="2">
                                    {
                                        data?.slides?.map((slide, i) => {
                                            return (
                                                <td className='member-name-td'>{slide?.full_name}</td>
                                            )
                                        })
                                    }
                                </td>
                            ))
                        }
                    </tr>
                </Table>
            </div>
        </div>
    )
}

export default ScheduleTable